import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function InputSelect(props) {
  return (
    <Listbox value={props.currentOption} onChange={props.setOption}>
      <Listbox.Label className="block text-base font-semibold text-gray-700">
        {props.name}
      </Listbox.Label>
      <div className="mt-1 relative">
        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
          <span className="flex items-center">
            <img
              src={props.currentOption.logo}
              alt=""
              className="flex-shrink-0 h-6 w-6"
            />
            <span className="ml-3 block truncate">
              {props.currentOption.name}
            </span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            style={{ zIndex: '99999' }}
            className="absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          >
            {props.options.map((item) => (
              <Listbox.Option
                key={item.id}
                className={({ active }) =>
                  classNames(
                    active ? 'text-white bg-schmeckn-normal' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9',
                  )
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      <img
                        src={item.logo}
                        alt=""
                        className="flex-shrink-0 h-6 w-6"
                      />
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'ml-3 block truncate',
                        )}
                      >
                        {item.name}
                      </span>
                    </div>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-gray-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
