import React from 'react';
import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ItemExtraElem from './ItemExtraElem';

function ItemExtras(props) {
  const addExtraElem = () => {
    let tmpElems = props.allExtras;
    tmpElems.push({
      id: tmpElems.length,
      name: '',
      description: '',
      menuExtraType: 'checkbox',
      options: [
        {
          priceDiff: '',
        },
      ],
    });
    props.setAllExtras([...tmpElems]);
  };

  const removeExtraElem = (extra) => {
    if (Object.prototype.hasOwnProperty.call(extra, 'uuid')) {
      let tmpExtrasToDelete = props.extrasToDelete;
      tmpExtrasToDelete.push(extra);
      props.setExtrasToDelete(tmpExtrasToDelete);
    }
    props.setAllExtras([
      ...props.allExtras.filter((item) => item.id !== extra.id),
    ]);
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        {props.allExtras.map((extra) => (
          <Col span={24} key={extra.id}>
            <ItemExtraElem
              extra={extra}
              editMode={props.editMode}
              allExtras={props.allExtras}
              removeExtraElem={() => removeExtraElem(extra)}
              extrasToDelete={props.extrasToDelete}
              setExtrasToDelete={props.setExtrasToDelete}
            />
          </Col>
        ))}
        <Col span={7}>
          <Button
            type="dashed"
            onClick={() => addExtraElem()}
            style={{ width: '100%' }}
            icon={<PlusOutlined />}
          >
            Hinzufügen
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ItemExtras;
