import React from 'react';

export default function Imprint() {
  return (
    <>
      <div className={'text-xl font-bold pb-2'}>Impressum</div>
      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        schmeckn Ergel &amp; Sch&auml;fer GbR
        <br />
        Friedrich-Ebert-Stra&szlig;e 5<br />
        35428 Langg&ouml;ns
      </p>

      <p>
        <strong>Vertreten durch:</strong>
        <br />
        Marvin Ergel
        <br />
        Max Robin Sch&auml;fer
      </p>

      <h2>Kontakt</h2>
      <div>
        <div className={'flex items-center'}>
          <div className={'mr-2'}>Telefon:</div>
          <img
            alt={'legal_phone_number'}
            src={'/images/legal/imprint_phone.png'}
            style={{ width: 'auto', height: 15 }}
          />
          <br />
        </div>
        <div className={'flex items-center'}>
          <div className={'mr-2'}>E-Mail:</div>
          <img
            alt={'legal_email'}
            src={'/images/legal/imprint_email.png'}
            style={{ width: 'auto', height: 16 }}
          />
          <br />
        </div>
        <p />
      </div>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{' '}
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/consumers/odr/
        </a>
        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir nehmen an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist die
        Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V.,
        Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein (
        <a
          href="https://www.verbraucher-schlichter.de"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.verbraucher-schlichter.de
        </a>
        ).
      </p>

      <p>
        Quelle:{' '}
        <a href="https://www.e-recht24.de/impressum-generator.html">
          https://www.e-recht24.de/impressum-generator.html
        </a>
      </p>
    </>
  );
}
