import React, { useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
  Switch,
  Tag,
  Tooltip,
  Select,
} from 'antd';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axiosApiInstance, { logout } from '../../Service/API';
import { useIdentity } from '../Utils/useIdentity';

const { Header } = Layout;
const { Option } = Select;

function DashboardHeader(props) {
  const { user, tenant, setTenant } = useIdentity();
  const [tenants, setTenants] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([
    {
      key: 'paypal',
      name: 'PayPal',
      active: false,
    },
    {
      key: 'stripe',
      name: 'Stripe',
      active: false,
    },
  ]);

  useEffect(() => {
    let tmpTenantsList = [];
    if (user.role === 'admin') {
      axiosApiInstance.get('/tenants').then((response) => {
        response.data.forEach((tenant) => {
          tmpTenantsList.push(tenant);
        });
      });
      setTenants(tmpTenantsList);
    }
  }, []);

  useEffect(() => {
    setPaymentStatus([
      {
        key: 'paypal',
        name: 'PayPal',
        active: !!props.restaurant?.paymentMethods?.includes('paypal'),
      },
      {
        key: 'stripe',
        name: 'Stripe',
        active: !!props.restaurant?.paymentMethods?.includes('stripe'),
      },
    ]);
  }, [props.restaurant]);

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<SettingOutlined />}>
        <Link to={`/dashboard/settings`}>Einstellungen</Link>
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<LogoutOutlined />}
        onClick={() => {
          logout('user');
          props.disconnectSse();
        }}
      >
        <span>Abmelden</span>
      </Menu.Item>
    </Menu>
  );

  const changeCanAcceptOrders = (e) => {
    axiosApiInstance
      .patch(`/restaurants/${props.restaurant.uuid}`, { canAcceptOrders: e })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.canAcceptOrders) {
            message.success('Bestellungen sind aktiviert');
          } else {
            message.warning('Bestellungen sind deaktiviert');
          }
          props.setRestaurant(response.data);
        }
      });
  };

  return (
    <Header style={{ background: 'white', padding: '0 16px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {props.restaurant && (
            <>
              {paymentStatus.map((item) => (
                <Tag
                  key={item.key}
                  icon={
                    item.active ? (
                      <CheckCircleOutlined />
                    ) : (
                      <ExclamationCircleOutlined />
                    )
                  }
                  color={item.active ? 'success' : 'warning'}
                >
                  {item.name}
                </Tag>
              ))}
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={props.restaurant.canAcceptOrders}
                onClick={(e) => changeCanAcceptOrders(e)}
                style={{ marginLeft: '1em' }}
                size={'small'}
              />
              <span style={{ marginLeft: '0.5em', marginRight: '1em' }}>
                Bestellungen annehmen?
              </span>
              {user.role === 'admin' && (
                <Select
                  defaultValue={`${tenant?.name} - ${tenant?.email}`}
                  onChange={(e, k) => setTenant(k['obj'])}
                  size={'small'}
                  style={{ width: '25em' }}
                >
                  {tenants.map((tenant) => {
                    return (
                      <Option
                        value={tenant.uuid}
                        obj={tenant}
                        key={tenant.uuid}
                      >
                        {`${tenant.name} - ${tenant.email}`}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </>
          )}
        </div>
        <div>
          {props.sseStatus ? (
            <Tooltip
              placement="bottom"
              title={'Neue Bestellungen werden automatisch angezeigt'}
            >
              <Tag
                key="sseConnected"
                icon={<CheckCircleOutlined />}
                color="success"
              >
                Echtzeitverbindung
              </Tag>
            </Tooltip>
          ) : (
            <Tooltip
              placement="bottom"
              title={
                'Sollte sich die Echtzeitverbindung nicht wiederherstellen, kontaktiere bitte unseren Support'
              }
            >
              <Tag
                key="sseConnected"
                icon={<ExclamationCircleOutlined />}
                color="error"
              >
                Echtzeitverbindung
              </Tag>
            </Tooltip>
          )}
          <Dropdown overlay={menu} trigger={['click']} arrow="true">
            <Button size={'small'}>
              <div>
                {user.email} <DownOutlined />
              </div>
            </Button>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
}

export default DashboardHeader;
