import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Radio, notification, message } from 'antd';
import axiosApiInstance from '../Service/API';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

function Login(props) {
  const [loginOrSignup, setLoginOrSignup] = useState('login');
  const [loadLoginOrSignup, setLoadLoginOrSignup] = useState(false);
  const [afterRegistration, setAfterRegistration] = useState(false);
  const search = useLocation().search;

  // TODO: Remove url params
  useEffect(() => {
    let message = {
      type: 'success',
      text: '',
    };
    const logoutParam = new URLSearchParams(search);
    if (logoutParam.get('logout') === 'session') {
      message.type = 'warning';
      message.text = 'Sie wurden wegen inaktivität automatisch abgemeldet';
    } else if (logoutParam.get('logout') === 'user') {
      notification[message.type]({
        message: 'Abgemeldet',
        description: message.text,
      });
    }
  }, []);

  const onLogin = async (values) => {
    setLoadLoginOrSignup(true);

    try {
      const response = await axiosApiInstance.post('/auth/signin', values);
      localStorage.setItem('tenantsUUID', response.data.uuid);

      if (response.status === 201) {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        props.history.push('/dashboard/overview');
      }
    } catch (err) {
      notification['error']({
        message: 'Email oder Passwort falsch',
        description: 'Bitte überprüfen Sie ihre Eingaben',
      });
    }
    setLoadLoginOrSignup(false);
  };

  const onSignup = async (values) => {
    setLoadLoginOrSignup(true);
    try {
      const response = await axiosApiInstance.post(
        '/auth/signup/seller',
        values,
      );
      if (response.status === 201) {
        setAfterRegistration(true);
      } else {
        message.success(
          'Registrierung fehlgeschlagen, bitte probiere es nachher erneut!',
        );
        console.warn(response);
      }
    } catch (error) {
      notification['error']({
        message: 'Registrierung fehlgeschlagen',
        description: 'Bitte überprüfe deine Eingaben',
      });
    }
    setLoadLoginOrSignup(false);
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={'bg-schmeckn-login'}
    >
      <div
        style={{
          padding: '2em 5em',
          backdropFilter: 'blur(14px) saturate(200%)',
          backgroundColor: 'rgba(255,255,255,0.6)',
          borderRadius: '12px',
          border: '1px solid rgba(255, 255, 255, 0.125)',
        }}
      >
        <div
          style={{
            fontSize: '26px',
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1em',
          }}
        >
          {afterRegistration ? 'Registierung Erfolgreich!' : 'Willkommen'}
        </div>
        {afterRegistration ? (
          <>
            <p>Wir haben die eine E-Mail geschickt!</p>
            <p>
              Bitte klicke auf den Link aus der E-Mail, <br />
              um dein Konto zu aktivieren.
            </p>
          </>
        ) : (
          <>
            <Radio.Group
              defaultValue="login"
              buttonStyle="solid"
              style={{ width: '100%' }}
              onChange={(e) => setLoginOrSignup(e.target.value)}
            >
              <Radio.Button
                value="login"
                style={{ width: '50%', marginBottom: '2em' }}
              >
                Anmelden
              </Radio.Button>
              <Radio.Button value="signup" style={{ width: '50%' }}>
                Registrieren
              </Radio.Button>
            </Radio.Group>
            {loginOrSignup === 'login' ? (
              <Form initialValues={{ remember: true }} onFinish={onLogin}>
                <Form.Item
                  name="email"
                  type="email"
                  rules={[
                    { required: true, message: 'Bitte gib deine E-Mail ein' },
                  ]}
                  style={{ marginBottom: '1em' }}
                >
                  <Input
                    addonBefore={<MailOutlined />}
                    placeholder="Email"
                    autoComplete="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Bitte gib dein Passwort ein' },
                  ]}
                  style={{ marginBottom: '2px' }}
                >
                  <Input
                    addonBefore={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    autoComplete="password"
                  />
                </Form.Item>
                <button
                  className={'text-schmeckn-normal'}
                  style={{ marginBottom: '1.5em' }}
                >
                  Passwort vergessen?
                </button>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    loading={loadLoginOrSignup}
                  >
                    Anmelden
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form initialValues={{ remember: true }} onFinish={onSignup}>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'Bitte gib deinen Namen ein' },
                  ]}
                  style={{ marginBottom: '1em' }}
                >
                  <Input
                    addonBefore={<UserOutlined />}
                    placeholder="Name"
                    autoComplete="name"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Bitte gib deine E-Mail ein' },
                  ]}
                  style={{ marginBottom: '1em' }}
                >
                  <Input
                    addonBefore={<MailOutlined />}
                    placeholder="Email"
                    autoComplete="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Bitte gib dein Passwort ein' },
                  ]}
                  style={{ marginBottom: '2px' }}
                >
                  <Input
                    addonBefore={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    autoComplete="password"
                    style={{ marginBottom: '1.5em' }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    loading={loadLoginOrSignup}
                  >
                    Account erstellen
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Login;
