import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import axiosApiInstance from '../../Service/API';
import BackendModal from '../Utils/BackendModal';
import ItemExtras from './ItemExtras';
import { ConvertPriceAmount, ConvertPriceToMinor } from '../../Service/Helper';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const { Option } = Select;

function Items(props) {
  const [oldExtras, setOldExtras] = useState([]);
  const [allExtras, setAllExtras] = useState([]);
  const [extrasToDelete, setExtrasToDelete] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [allergeneElems, setAllergeneElems] = useState([]);
  const [tagElems, setTagElems] = useState([]);
  const [copyFromSelectElem, setCopyFromSelectElem] = useState([]);
  const [currentlyEditingUUID, setCurrentlyEditingUUID] = useState('');
  const [copyFromUUID, setCopyFromUUID] = useState(null);
  const [loadinSaveCopyExtras, setLoadinSaveCopyExtras] = useState(false);
  const [tmpFormValues, setTmpFormValues] = useState({
    name: '',
    description: '',
    aas: [],
    tags: [],
    price: null,
  });
  const [form] = Form.useForm();

  // TableSort
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex,
      ).filter((el) => !!el);
      setDataSource(newData);
    }
  };

  // TableSort
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  // TableSort
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.uuid === restProps['data-row-key'],
    );
    return <SortableItem index={index} {...restProps} />;
  };

  // GET AAS and tags
  useEffect(() => {
    axiosApiInstance.get('menu-aas').then((response) => {
      let elems = [];
      response.data.forEach((item) => {
        elems.push(
          <Option key={item.uuid}>{`${item.shortname}. ${item.name}`}</Option>,
        );
      });
      setAllergeneElems(elems);
    });
    axiosApiInstance.get('menu-tags').then((response) => {
      let elems = [];
      response.data.forEach((item) => {
        elems.push(<Option key={item.uuid}>{item.name}</Option>);
      });
      setTagElems(elems);
    });
  }, []);

  // Fill dataSource for meal table
  useEffect(() => {
    props.currentCard.categories.forEach((category) => {
      if (category.uuid === props.selectedCategory.uuid) {
        setDataSource(category.items);
      }
    });

    // Clear table once category gets deleted
    if (props.selectedCategory === '') {
      setDataSource([]);
    }
  }, [props.currentCard, props.selectedCategory]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  // Helper function to store values. Was implemented because if you delete an extra, the form will otherwise be cleared
  const updateFormFields = (field, value) => {
    let tmpFormFields = { ...tmpFormValues };
    tmpFormFields[field] = value;
    setTmpFormValues(tmpFormFields);
  };

  const clearOnModalClose = () => {
    form.resetFields();
    setTmpFormValues({
      name: '',
      description: '',
      aas: [],
      tags: [],
      price: null,
    });
    setAllExtras([]);
    setExtrasToDelete([]);
    setCopyFromUUID(null);
    setLoadinSaveCopyExtras(false);
    setOldExtras([]);
  };

  const serializeExtra = (extra) => {
    delete extra.id;
    delete extra.uuid;
    delete extra.createdAt;
    delete extra.updatedAt;
    delete extra.isActive;
    delete extra.tenantUuid;
    extra.options.forEach((option) => {
      delete option.id;
      delete option.uuid;
      option.priceDiff = ConvertPriceToMinor(option.priceDiff);
    });

    return extra;
  };

  const prepareExtras = (response) => {
    if (JSON.stringify(allExtras) !== JSON.stringify(oldExtras)) {
      // PATCH extras and make ready for POST
      let tmpNewExtras = [];
      allExtras.forEach((extra) => {
        if (Object.prototype.hasOwnProperty.call(extra, 'uuid')) {
          oldExtras.forEach((oldExtra) => {
            if (extra.uuid === oldExtra.uuid) {
              if (JSON.stringify(oldExtra) !== JSON.stringify(extra)) {
                delete extra.menuExtraType;
                axiosApiInstance
                  .patch(`/menu-extras/${extra.uuid}`, serializeExtra(extra))
                  .then();
              }
            }
          });
        } else {
          extra['item'] = { uuid: response.data.uuid };
          tmpNewExtras.push(serializeExtra(extra));
        }
      });

      // DELETE extras
      if (extrasToDelete.length > 0) {
        extrasToDelete.forEach((extra) => {
          axiosApiInstance.delete(`menu-extras/${extra.uuid}`).then();
        });
      }

      // POST extras
      if (tmpNewExtras.length > 0) {
        axiosApiInstance
          .post(`menu-extras/bulk`, { extras: tmpNewExtras })
          .then();
      }
    }
  };

  const loadItemToEdit = (itemUUID) => {
    axiosApiInstance.get(`/menu-items/${itemUUID}`).then((response) => {
      let currentAas = [];
      let currentTags = [];
      let tmpAllExtras = [];
      let tmpElems = [];

      setCurrentlyEditingUUID(response.data.uuid);
      setCurrentRow(response.data);

      response.data.aas.forEach((item) => {
        currentAas.push(item.uuid);
      });
      response.data.tags.forEach((item) => {
        currentTags.push(item.uuid);
      });
      setTmpFormValues({
        name: response.data.name,
        description: response.data.description,
        aas: currentAas,
        tags: currentTags,
        price: ConvertPriceAmount(response.data.price),
      });

      response.data.extras.forEach((extra, index) => {
        extra.options.forEach((option, index) => {
          option.id = index;
          option.priceDiff = ConvertPriceAmount(option.priceDiff);
        });
        tmpAllExtras.push({ id: index, ...extra });
      });

      setAllExtras(tmpAllExtras);
      setOldExtras(JSON.parse(JSON.stringify(tmpAllExtras)));

      props.currentCard.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (response.data.uuid !== item.uuid) {
            if (item.extras.length > 0) {
              tmpElems.push(
                <Option value={item.uuid} key={item.uuid}>
                  {item.name} - {item.extras.length} Extras
                </Option>,
              );
            }
          }
        });
      });
      setCopyFromSelectElem(tmpElems);
    });
  };

  const copyExtrasFromItem = () => {
    setLoadinSaveCopyExtras(true);
    axiosApiInstance
      .put('menu-extras/copy-from-menu-item', {
        fromItem: {
          uuid: copyFromUUID,
        },
        toItem: {
          uuid: currentlyEditingUUID,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          message.success('Kopieren der Extras Erfolgreich');
          loadItemToEdit(currentlyEditingUUID);
        } else {
          message.error('Kopieren der Extras fehlgeschalgen');
        }
      });
    setLoadinSaveCopyExtras(false);
  };

  const columns = [
    {
      title: 'Sortieren',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
    },
    {
      title: 'Preis',
      dataIndex: 'price',
      render: (value) => {
        return `${ConvertPriceAmount(value)}€`;
      },
    },
    {
      title: 'Aktion',
      key: 'action',
      width: '27%',
      render: (row) => (
        <Row gutter={12}>
          <Col span={12}>
            <Button
              block
              size="small"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                form.resetFields();
                loadItemToEdit(row.uuid);
                toggleModal();
                setEditMode(true);
              }}
            >
              Bearbeiten
            </Button>
          </Col>
          <Col span={12}>
            <Popconfirm
              title={`Gericht wirklich löschen?`}
              onConfirm={() => {
                axiosApiInstance
                  .delete(`/menu-items/${row.uuid}`)
                  .then((response) => {
                    if (response.status === 200) {
                      message.success('Gericht erfolgreich gelöscht');
                      props.getMenuCards();
                    } else {
                      message.error('Gericht löschen fehlgeschlagen');
                    }
                  })
                  .catch(() => {
                    notification['error']({
                      message: 'Fehler',
                      description:
                        'Sollte dieser Fehler öfter auftreten, kontaktieren Sie bitte den Support',
                    });
                  });
              }}
              okText="Ja"
              cancelText="Nein"
              disabled={props.selectedCategory === ''}
            >
              <Button
                block
                disabled={props.selectedCategory === ''}
                size="small"
                type="danger"
                icon={<DeleteOutlined />}
              >
                Löschen
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Button
            disabled={props.selectedCategory === ''}
            size="small"
            style={{ marginBottom: '10px' }}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              toggleModal();
              setEditMode(false);
            }}
          >
            Hinzufügen
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            bordered
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="uuid"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </Col>
      </Row>
      <BackendModal
        name={'Gericht'}
        editMode={editMode}
        toggleModal={toggleModal}
        modalVisible={modalVisible}
        clearOnModalClose={clearOnModalClose}
        onOk={() => {
          setLoading(true);
          form
            .validateFields()
            .then((values) => {
              // Add allergenes to values
              let aasArr = [];
              if (values['aas'].length !== 0) {
                for (let aa of values['aas']) {
                  aasArr.push({ uuid: aa });
                }
              }
              values['aas'] = aasArr;

              // Add tags to values
              let tagsArr = [];
              if (values['tags'].length !== 0) {
                for (let tag of values['tags']) {
                  tagsArr.push({ uuid: tag });
                }
              }
              values['tags'] = tagsArr;
              values['price'] = ConvertPriceToMinor(values['price']);

              if (!editMode) {
                // Add category to values
                values['categories'] = [
                  {
                    uuid: props.selectedCategory.uuid,
                  },
                ];
                axiosApiInstance.post('menu-items', values).then((response) => {
                  if (response.status === 201) {
                    // Add extras
                    prepareExtras(response);
                    props.getMenuCards();
                    message.success('Gericht erfolgreich angelegt');
                    toggleModal();
                    form.resetFields();
                  } else {
                    message.error('Gericht anlegen fehlgeschlagen');
                  }
                  setLoading(false);
                });
              } else {
                axiosApiInstance
                  .patch(`menu-items/${currentRow.uuid}`, values)
                  .then((response) => {
                    if (response.status === 200) {
                      prepareExtras(response);
                      props.getMenuCards();
                      message.success('Gericht erfolgreich geändert');
                      toggleModal();
                      form.resetFields();
                    } else {
                      message.error('Gericht ändern fehlgeschlagen');
                    }
                    setLoading(false);
                  });
              }
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        content={
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form
                form={form}
                layout="vertical"
                fields={[
                  { name: 'name', value: tmpFormValues.name },
                  { name: 'description', value: tmpFormValues.description },
                  { name: 'aas', value: tmpFormValues.aas },
                  { name: 'tags', value: tmpFormValues.tags },
                  { name: 'price', value: tmpFormValues.price },
                ]}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  hasFeedback
                  rules={[
                    { required: true, message: 'Bitte den Namen eingeben' },
                  ]}
                  tooltip={{
                    title: (
                      <div>
                        Name des Gerichtes
                        <br /> z.B: Pizza, Nudeln, Salat
                      </div>
                    ),
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Input
                    placeholder="Pizza"
                    onChange={(e) => updateFormFields('name', e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Beschreibung"
                  name="description"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Bitte eine Beschreibung eingeben',
                    },
                  ]}
                  tooltip={{
                    title: (
                      <div>
                        Beschreibung des Gerichts
                        <br /> z.B: sehr lecker lol
                      </div>
                    ),
                    icon: <InfoCircleOutlined />,
                  }}
                  onChange={(e) =>
                    updateFormFields('description', e.target.value)
                  }
                >
                  <Input placeholder="Das ist eine Pizza" />
                </Form.Item>
                <Form.Item
                  label="Allergen"
                  name="aas"
                  tooltip={{
                    title: <div>Bitte ausfüllen!</div>,
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Bitte auswählen"
                    onChange={(e) => updateFormFields('aas', e)}
                  >
                    {allergeneElems}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Tags"
                  name="tags"
                  tooltip={{
                    title: <div>Bitte ausfüllen!</div>,
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Bitte auswählen"
                    onChange={(e) => updateFormFields('tags', e)}
                  >
                    {tagElems}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Preis"
                  name="price"
                  hasFeedback
                  rules={[
                    { required: true, message: 'Bitte einen Preis eingeben' },
                  ]}
                  tooltip={{
                    title: (
                      <div>
                        Preis des Gerichts
                        <br /> z.B: 13.37€
                      </div>
                    ),
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <InputNumber
                    decimalSeparator={','}
                    precision={2}
                    style={{ width: '33%' }}
                    width={'100%'}
                    placeholder="13.37€"
                    onChange={(e) => updateFormFields('price', e)}
                    min={0.0}
                    step={0.1}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '0.5em',
                }}
              >
                <h2 style={{ marginBottom: '0', marginRight: '0.5em' }}>
                  Extras hinzufügen
                </h2>
                <Tooltip title="Der Preis wird mit dem normalen Basis-Preis des Gerichts verrechnet. Negative Eingaben sind möglich.">
                  <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                </Tooltip>
              </div>
              <ItemExtras
                allExtras={allExtras}
                setAllExtras={setAllExtras}
                editMode={editMode}
                extrasToDelete={extrasToDelete}
                setExtrasToDelete={setExtrasToDelete}
              />
              {editMode && (
                <>
                  <Divider plain>oder</Divider>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '0.5em',
                    }}
                  >
                    <h2 style={{ marginBottom: '0', marginRight: '0.5em' }}>
                      Extras kopieren
                    </h2>
                    <Tooltip title="Beim kopieren der Extras, werden diese sofort gespeichert.">
                      <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                    </Tooltip>
                  </div>
                  <Row gutter={12}>
                    <Col span={18}>
                      <Select
                        placeholder="Bitte auswählen"
                        value={copyFromUUID}
                        allowClear
                        style={{ width: '100%' }}
                        onChange={(e) => setCopyFromUUID(e)}
                        disabled={loadinSaveCopyExtras}
                      >
                        {copyFromSelectElem}
                      </Select>
                    </Col>
                    <Col span={6}>
                      <Button
                        type="primary"
                        disabled={!copyFromUUID}
                        onClick={copyExtrasFromItem}
                        loading={loadinSaveCopyExtras}
                      >
                        Extras kopieren
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Items;
