import React from 'react';
import { Modal } from 'antd';

function BackendModal(props) {
  return (
    <>
      <Modal
        title={
          props.title ||
          `${props.name} ${props.editMode ? 'bearbeiten' : 'hinzufügen'}`
        }
        visible={props.modalVisible}
        onOk={props.onOk}
        onCancel={props.toggleModal}
        cancelText={'Abbrechen'}
        okText={props.okText || `Speichern`}
        width={props.name === 'Gericht' ? 1200 : 520}
        afterClose={props?.clearOnModalClose}
      >
        {props.content}
      </Modal>
    </>
  );
}

export default BackendModal;
