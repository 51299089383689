import React, { useEffect, useState } from 'react';
import { Col, Row, Input, InputNumber, Radio, Select } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

function ItemExtraElem(props) {
  const [extraType, setExtraType] = useState('checkbox');
  const [extraObject, setExtraObject] = useState(props.extra);

  useEffect(() => {
    if (props.editMode) {
      setExtraType(props.extra.menuExtraType);
    }
  }, []);

  // listen to each change
  useEffect(() => {
    let currentAllExtras = props.allExtras;
    let allExtrasIDs = [];
    for (let i = 0; i < currentAllExtras.length; i++) {
      allExtrasIDs.push(currentAllExtras[i]?.id);
      if (currentAllExtras[i]?.id === props.extra.id) {
        currentAllExtras[i] = extraObject;
      }
    }
    if (!allExtrasIDs.includes(props.extra.id)) {
      currentAllExtras.push(extraObject);
    }
  }, [extraObject]);

  const additionalExtraElem = () => {
    let tmpExtraObject = {
      ...extraObject,
      options: [
        ...extraObject.options,
        {
          id: extraObject.options.length + 1,
          name: '',
          priceDiff: '',
          isDefault: false,
        },
      ],
    };
    setExtraObject(tmpExtraObject);
  };

  const removeAdditionalExtra = (id) => {
    let tmpExtraObject = { ...extraObject };
    tmpExtraObject.options.forEach((item) => {
      if (item['id'] === id) {
        if (item['isDefault'] === true) {
          tmpExtraObject.options[0]['isDefault'] = true;
        }
      }
    });
    setExtraObject({
      ...tmpExtraObject,
      options: [...tmpExtraObject.options.filter((item) => item.id !== id)],
    });
  };

  const changeExtraValues = (field, value, id) => {
    let tmpExtraObject = { ...extraObject };
    if (extraType === 'checkbox') {
      if (field === 'title') {
        tmpExtraObject['name'] = value;
      } else {
        tmpExtraObject.options[0].priceDiff = value;
      }
    } else if (extraType === 'radio') {
      if (field === 'title') {
        tmpExtraObject['name'] = value;
      } else {
        tmpExtraObject.options.forEach((item) => {
          if (item.id === id) {
            if (field === 'name') {
              item['name'] = value;
            } else if (field === 'price') {
              item.priceDiff = value;
            }
          }
        });
      }
    }
    setExtraObject(tmpExtraObject);
  };

  const changeDefaultOption = (id) => {
    let tmpExtraObject = { ...extraObject };
    tmpExtraObject.options.forEach((item) => {
      item['isDefault'] = item['id'] === id;
    });
    setExtraObject(tmpExtraObject);
  };

  const changeExtraType = (e) => {
    let newExtraObject;
    if (e === 'checkbox') {
      newExtraObject = {
        id: props.extra.id,
        menuExtraType: 'checkbox',
        name: '',
        description: '',
        options: [
          {
            priceDiff: '',
          },
        ],
      };
    } else if (e === 'radio') {
      newExtraObject = {
        id: props.extra.id,
        menuExtraType: 'radio',
        name: '',
        description: '',
        options: [
          {
            id: 0,
            name: '',
            priceDiff: '',
            isDefault: true,
          },
          {
            id: 1,
            name: '',
            priceDiff: '',
            isDefault: false,
          },
        ],
      };
    }
    setExtraObject(newExtraObject);
    setExtraType(e);
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={7}>
          <Select
            defaultValue={'checkbox'}
            value={extraType}
            style={{ width: '100%' }}
            onChange={(e) => changeExtraType(e)}
          >
            <Option value="checkbox">Einfachauswahl</Option>
            <Option value="radio">Mehrfachauswahl</Option>
          </Select>
        </Col>
        {extraType === 'radio' ? (
          <Col span={13}>
            <Input
              placeholder={'Titel'}
              onChange={(e) => changeExtraValues('title', e.target.value)}
              value={extraObject.name}
            />
          </Col>
        ) : (
          <>
            <Col span={9}>
              <Input
                placeholder={'Name'}
                onChange={(e) => changeExtraValues('title', e.target.value)}
                value={extraObject.name}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                decimalSeparator={','}
                precision={2}
                parser={(value) => `${value}`.replace(/,/g, '.')}
                width={'100%'}
                placeholder="13.37€"
                onChange={(e) => changeExtraValues('price', e)}
                min={0.0}
                step={0.1}
                value={extraObject.options && extraObject.options[0].priceDiff}
              />
            </Col>
          </>
        )}
        <Col span={2}>
          <MinusCircleOutlined
            className="dynamic-delete-button"
            onClick={props.removeExtraElem}
          />
        </Col>
        {extraType === 'radio' && (
          <Col span={2}>
            <PlusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => additionalExtraElem()}
            />
          </Col>
        )}
      </Row>
      {/*add 2 options for radios*/}
      {extraType === 'radio' && (
        <>
          {extraObject.options.map((option, index) => (
            <Row
              gutter={[8, 8]}
              style={{ marginTop: '5px' }}
              key={`extraObject_${option.id}_${index}`}
            >
              <Col span={7}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <span style={{ marginRight: '5px' }}>Standard</span>
                  <Radio
                    checked={option.isDefault}
                    onClick={() => changeDefaultOption(option.id)}
                  />
                </div>
              </Col>
              <Col span={9}>
                <Input
                  placeholder={'Name'}
                  onChange={(e) =>
                    changeExtraValues('name', e.target.value, option.id)
                  }
                  value={option.name}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  decimalSeparator={','}
                  precision={2}
                  width={'100%'}
                  placeholder="13.37€"
                  onChange={(e) => changeExtraValues('price', e, option.id)}
                  step={0.1}
                  value={option.priceDiff}
                />
              </Col>
              {extraObject.options.length > 2 && option.isDefault === false && (
                <Col span={2}>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => removeAdditionalExtra(option.id)}
                  />
                </Col>
              )}
            </Row>
          ))}
        </>
      )}
    </>
  );
}

export default ItemExtraElem;
