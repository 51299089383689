import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row, Typography } from 'antd';
import axiosApiInstance from '../../Service/API';
import RestaurantOpeningHours from './RestaurantOpeningHours';
import TimeInProgress from './TimeInProgress';
import GmapsInput from '../../Utils/GmapsInput';
import { useIdentity } from '../Utils/useIdentity';

const { Title } = Typography;

const openNotificationWithIcon = (type, msg) => {
  notification[type]({
    message: msg,
  });
};

function UserSettings() {
  const { tenant } = useIdentity();
  const [form] = Form.useForm();
  const [address, setAddress] = useState({
    formattedAddress: '',
    placeId: '',
  });
  const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [openingHours, setOpeningHours] = useState([
    { day: 'monday', open: '0000', close: '0000' },
    { day: 'tuesday', open: '0000', close: '0000' },
    {
      day: 'wednesday',
      open: '0000',
      close: '0000',
    },
    { day: 'thursday', open: '0000', close: '0000' },
    { day: 'friday', open: '0000', close: '0000' },
    { day: 'saturday', open: '0000', close: '0000' },
    { day: 'sunday', open: '0000', close: '0000' },
  ]);
  const [timeInProgressObj, setTimeInProgressObj] = useState({
    spanSize: 10,
    spanTime: 10,
    baseTime: 10,
  });

  useEffect(() => {
    axiosApiInstance.get('/restaurants/self').then((response) => {
      setAddress({
        formattedAddress: `${response.data[0].address.street}, ${response.data[0].address.postalCode} ${response.data[0].address.city}`,
        placeId: response.data[0].placeId,
      });
      setCurrentRestaurant(response.data[0]);
      setTimeInProgressObj(response.data[0].estimatedTimeInProgress);
      setOpeningHours(response.data[0].openingHours);
      form.setFieldsValue({
        name: response.data[0].name,
        description: response.data[0].description,
        placeId: `${response.data[0].address.street}, ${response.data[0].address.postalCode} ${response.data[0].address.city}`,
      });
    });
  }, [tenant]);

  const onFinish = (values) => {
    values['placeId'] = address.placeId;
    values['openingHours'] = openingHours;
    values['estimatedTimeInProgress'] = timeInProgressObj;
    axiosApiInstance
      .patch(`/restaurants/${currentRestaurant.uuid}`, values)
      .then((response) => {
        if (response.status === 200) {
          openNotificationWithIcon('success', 'Daten Erfolgreich geändert!');
        } else {
          openNotificationWithIcon('error', 'Fehler beim ändern der Daten!');
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    openNotificationWithIcon('error', 'Fehler beim Ändern der Daten!');
  };

  const getLandingPageUrl = () =>
    `${window.location.origin}/restaurant/${currentRestaurant?.slug}`;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Title level={3}>Dein Restaurant</Title>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href={getLandingPageUrl()} target="_blank" rel="noreferrer">
          {getLandingPageUrl()}
        </a>
      </div>
      <br />
      <Form
        form={form}
        name="restaurantSettings"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={12}>
          <Col span={12}>
            <label>Name</label>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: 'Bitte einen Namen eingeben' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label>Beschreibung</label>
            <Form.Item
              name="description"
              rules={[
                { required: true, message: 'Bitte eine Beschreibung eingeben' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <label>Adresse</label>
        <Form.Item
          name="placeId"
          rules={[
            {
              required: true,
              message: 'Du benötigst eine Adresse für dein Restaurant',
            },
          ]}
        >
          <GmapsInput
            value={address.formattedAddress}
            placeholder={address.formattedAddress}
            onChange={(e) => {
              setAddress({
                formattedAddress: e.value.description,
                placeId: e.value.place_id,
              });
            }}
            style={{
              container: (provided) => ({
                ...provided,
                position: 'relative',
                margin: 0,
                padding: 0,
              }),
              control: () => ({
                display: 'flex',
                borderWidth: '1px',
                borderRadius: '',
                fontSize: '14px',
                lineHeight: '1.5rem',
                borderColor: '#d9d9d9',
                padding: '4px 11px',
              }),
              placeholder: () => ({
                color: '#000',
              }),
              indicatorsContainer: () => ({
                display: 'none',
              }),
              input: () => ({
                margin: 0,
                padding: 0,
              }),
              valueContainer: (provided) => ({
                ...provided,
                margin: 0,
                padding: 0,
              }),
            }}
          />
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={4}>Bearbeitungszeit für Bestellungen</Title>
        </div>
        <p style={{ textAlign: 'center' }}>
          Deine Kunden würden gerne wissen wann ihr Hunger <b>ungefähr</b>{' '}
          gestillt wird. Trage dazu einfach die nötigen Daten ein und wir
          kümmern uns um den Rest.
        </p>
        <TimeInProgress
          timeInProgressObj={timeInProgressObj}
          setTimeInProgressObj={setTimeInProgressObj}
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={4}>Öffnungszeiten</Title>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={5}>00:00 - 00:00 für Geschlossen</Title>
        </div>
        <RestaurantOpeningHours
          setOpeningHours={setOpeningHours}
          openingHours={openingHours}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Restaurant speichern
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default UserSettings;
