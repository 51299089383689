import React, { useEffect, useState } from 'react';
import InputText from '../../Generic/InputText';
import InputTextarea from '../../Generic/InputTextarea';
import GmapsInput from '../../../Utils/GmapsInput';

function UserDataContainer(props) {
  const [ipAddress, setIpAddress] = useState({ ip: '' });
  const [validationBorderColor, setValidationBorderColor] = useState('#d1d5db');

  useEffect(() => {
    if (ipAddress.ip === '') {
      fetch('https://ipapi.co/json')
        .then((res) => res.json())
        .then(
          (data) => {
            setIpAddress(data);
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }, []);

  const changeInputFields = (field, value) => {
    let tmpInputsFields = { ...props.checkoutFormFields };
    if (field === 'formattedAddress') {
      tmpInputsFields['addressObject'] = value;
      tmpInputsFields['formattedAddress'] = value.value.description;
      tmpInputsFields['placeId'] = value.value.place_id;
    } else {
      tmpInputsFields[field] = value;
    }
    props.setCheckoutFormFields(tmpInputsFields);
  };

  return (
    <>
      <div
        className={
          'border-b text-md font-base text-gray-600 tracking-wider mb-2 mt-6'
        }
      >
        Wer hat hunger?
      </div>
      <div className={'space-y-2 mb-4'}>
        <InputText
          label={'E-Mail'}
          type={'email'}
          // placeholder={"beispiel@email.de"}
          value={props.checkoutFormFields.email}
          onChange={(e) => changeInputFields('email', e.target.value)}
          validation={props.validation.email}
          required={true}
          errorText={
            props.validation.email === 'error' &&
            'Bitte eine gültige E-Mail eingeben.'
          }
          // onBlur={() => props.validateOnBlur("email")}
        />
        <InputText
          label={'Telefon'}
          type={'phone'}
          autoComplete={'tel'}
          // placeholder={"01234 567890"}
          value={props.checkoutFormFields.phone}
          onChange={(e) => changeInputFields('phone', e.target.value)}
          validation={props.validation.phone}
          required={true}
          errorText={
            props.validation.phone === 'error' &&
            'Bitte eine gültige Telefonnummer eingeben.'
          }
          // onBlur={() => props.validateOnBlur("phone")}
        />
        <InputText
          label={'Vorname'}
          autoComplete={'given-name'}
          // placeholder={"Max"}
          value={props.checkoutFormFields.firstName}
          onChange={(e) => changeInputFields('firstName', e.target.value)}
          validation={props.validation.firstName}
          required={true}
          errorText={
            props.validation.firstName === 'error' &&
            'Vorname darf nicht leer sein.'
          }
          // onBlur={() => props.validateOnBlur("firstName")}
        />
        <InputText
          label={'Nachname'}
          autoComplete={'family-name'}
          // placeholder={"Mustermann"}
          value={props.checkoutFormFields.lastName}
          onChange={(e) => changeInputFields('lastName', e.target.value)}
          validation={props.validation.lastName}
          required={true}
          errorText={
            props.validation.lastName === 'error' &&
            'Nachname darf nicht leer sein.'
          }
          // onBlur={() => props.validateOnBlur("lastName")}
        />
        <div>
          <label className="block text-base font-semibold text-gray-600">
            Adresse<span className={'text-red-500'}>*</span>
          </label>
          <span className={'text-red-500 font-bold'}>
            {props.validation.formattedAddress === 'error' &&
              'Bitte eine gültige Adresse eingeben und den Vorschlag auswählen.'}
          </span>
          <div className="relative rounded-md shadow-sm">
            <GmapsInput
              value={props.checkoutFormFields.addressObject}
              onChange={(e) => {
                changeInputFields('formattedAddress', e);
              }}
              validation={props.validation.formattedAddress}
              setValidationBorderColor={setValidationBorderColor}
              style={{
                container: (provided) => ({
                  ...provided,
                  position: 'relative',
                  borderRadius: '0.375rem',
                  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                  margin: 0,
                  padding: 0,
                }),
                control: () => ({
                  display: 'flex',
                  borderWidth: '1px',
                  borderRadius: '0.375rem',
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  borderColor: validationBorderColor,
                  padding: '0.5rem 0.75rem',
                }),
                placeholder: () => ({
                  color: '#9ca3af',
                }),
                indicatorsContainer: () => ({
                  display: 'none',
                }),
                input: () => ({
                  margin: 0,
                  padding: 0,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  margin: 0,
                  padding: 0,
                }),
              }}
            />
          </div>
        </div>
        <label
          htmlFor="price"
          className="block text-base font-semibold text-gray-600"
        >
          Bestellnotiz
        </label>
        <InputTextarea
          placeholder={''}
          value={props.checkoutFormFields.comment}
          onChange={(e) => changeInputFields('comment', e.target.value)}
        />
      </div>
      {ipAddress.ip !== '' && (
        <div className={'mb-2 italic'}>
          Achtung: Um uns und den Restaurantbetreiber vor Betrug zu schützen,
          wurde ihre IP-Adresse protokolliert:{' '}
          <span className={'font-semibold'}>{ipAddress.ip}</span>
        </div>
      )}
    </>
  );
}

export default UserDataContainer;
