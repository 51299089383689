import React, { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { ConvertPriceAmount } from '../../../Service/Helper';

export default function ItemExtraRadio(props) {
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    if (props.operation === 'add') {
      let newItemExtras = props.itemExtras;
      props.itemExtra.options.forEach((option, index) => {
        if (option.isDefault) {
          setCurrentValue(option.name);
          newItemExtras.push({
            uuid: props.itemExtra.uuid,
            optionIndex: index,
            menuExtraType: 'radio',
            name: option.name,
            priceDiff: option.priceDiff,
          });
        }
      });
      props.setItemExtras(newItemExtras);
    } else if (props.operation === 'edit') {
      props.itemExtras.forEach((extra) => {
        if (extra.uuid === props.itemExtra.uuid) {
          setCurrentValue(extra.name);
        }
      });
    }
  }, []);

  const changeRadioValue = (value) => {
    let newItemExtras = [...props.itemExtras];
    newItemExtras.forEach((extra) => {
      if (extra.uuid === props.itemExtra.uuid) {
        props.itemExtra.options.forEach((option, index) => {
          if (option.name === value) {
            extra.name = value;
            extra.optionIndex = index;
            extra.priceDiff = option.priceDiff;
          }
        });
      }
    });
    setCurrentValue(value);
    props.setItemExtras(newItemExtras);
  };

  return (
    <RadioGroup value={currentValue} onChange={(e) => changeRadioValue(e)}>
      <div className="flex justify-between space-x-4">
        {props.itemExtra.options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option.name}
            className={({ checked }) =>
              `${
                checked ? 'bg-schmeckn-normal text-white' : 'bg-white'
              } rounded shadow cursor-pointer w-1/2 checked:bg-red-900 focus:outline-none`
            }
          >
            {({ checked }) => (
              <>
                <div className="text-sm p-1">
                  <RadioGroup.Label
                    as="div"
                    className={`${checked ? 'text-white font-bold' : ''}`}
                  >
                    <span>{option.name}</span>
                    <br />
                    <span className={''}>
                      {ConvertPriceAmount(
                        props.fullItem.basePrice + option.priceDiff,
                      )}
                      €
                    </span>
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
