import React, { useEffect, useState } from 'react';
import { Row, Col, Card, notification } from 'antd';
import Items from './Items';
import Categories from './Categories';
import { useParams, useHistory } from 'react-router-dom';
import axiosApiInstance from '../../Service/API';

function CategoriesItems(props) {
  const [menuCard, setMenuCard] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  let { menuCardUuid } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchMenuCard();
  }, []);

  const fetchMenuCard = () => {
    setLoading(true);
    axiosApiInstance
      .get(`/menu-cards/${menuCardUuid}`)
      .then((response) => {
        if (response.status === 200) {
          setMenuCard(response.data);
        }
      })
      .catch(() => {
        history.push('/dashboard/menu-cards/');
        notification['error']({
          message: 'Fehler',
          description: 'Speisekarte nicht gefunden',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card
        title={`Kategorien und Gerichte für ${menuCard.name || ''}`}
        style={{ height: '100%', overflow: 'auto' }}
        loading={loading}
      >
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={6}
            style={{ padding: '0 1em' }}
          >
            <h1>
              <center>Kategorien</center>
            </h1>
            <Categories
              setLoading={props.setLoading}
              currentCard={menuCard}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              getMenuCards={fetchMenuCard}
              setSelectedCategoryKeys={props.setSelectedCategoryKeys}
              selectedCategoryKeys={props.selectedCategoryKeys}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            xxl={18}
            style={{ padding: '0 1em' }}
          >
            <h1>
              <center>Gerichte</center>
            </h1>
            <Items
              getMenuCards={fetchMenuCard}
              currentCard={menuCard}
              selectedCategory={selectedCategory}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default CategoriesItems;
