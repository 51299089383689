import React, { useState } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import axiosApiInstance from '../../Service/API';
import { CheckCircleIcon } from '@heroicons/react/outline';

function Subscription({
  isActive = false,
  bestBuy = false,
  name = '',
  description = '',
  pricePerMonth = '0',
  features = [],
  plan,
}) {
  const [loading, setLoading] = useState(false);

  const subscribe = () => {
    setLoading(true);
    axiosApiInstance
      .get('/stripe/seller/subscribe/' + plan)
      .then((response) => {
        window.location.href = response.data.url;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const edit = () => {
    setLoading(true);
    axiosApiInstance
      .get('/stripe/seller/portal')
      .then((response) => {
        window.location.href = response.data.url;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: 'white',
        borderRadius: '1em',
        padding: '1em',
      }}
      key={name}
    >
      <div style={{ fontSize: '30px', fontWeight: '500' }}>{name}</div>
      <div
        style={{
          fontSize: '16px',
          fontWeight: '400',
          marginBottom: '1em',
          color: '#9d9d9d',
          height: '30px',
        }}
      >
        {description}
      </div>
      <div>
        <span
          style={{ fontSize: '40px', fontWeight: '700', marginRight: '3px' }}
        >
          {pricePerMonth}€
        </span>
        <span style={{ fontSize: '20px', fontWeight: '500', color: '#9d9d9d' }}>
          /Monat
        </span>
      </div>

      <Divider />
      <Button
        loading={loading}
        style={{ width: '100%', height: '3rem', borderRadius: '10px' }}
        onClick={isActive ? edit : subscribe}
      >
        {isActive ? 'Bearbeiten' : 'Abonnieren'}
      </Button>

      <Divider />
      {features.length > 0 &&
        features.forEach((feature) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            key={feature}
          >
            <CheckCircleIcon
              className="h-6 w-6"
              style={{ margin: '5px 0', marginRight: '3px', color: '#22b000' }}
            />
            <span style={{ width: '100%' }}>{feature}</span>
          </div>
        ))}
    </div>
  );
}

function Subscriptions({ user }) {
  const tenant = user.tenants[0];
  if (!tenant) return <></>;

  const basicFeatures = ['Feature A', 'Feature B', 'Feature C'];

  const proFeatures = [
    'Alles aus Basic',
    'Feature D',
    'Feature E',
    'Feature F',
    'Feature G',
  ];

  return (
    <>
      {tenant && (
        <Row gutter={[24, 24]} justify="center">
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <Subscription
              name="Basic"
              isActive={tenant?.plan === 'basic'}
              plan="basic"
              pricePerMonth="100"
              description="Du willst schauen ob dir schmeckn ... schmeckt? "
              features={basicFeatures}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <Subscription
              name="Pro"
              isActive={tenant?.plan === 'pro'}
              plan="pro"
              pricePerMonth="200"
              description="Hol alles aus schmeckn raus!"
              features={proFeatures}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default Subscriptions;
