import React from 'react';

function InputText(props) {
  return (
    <div>
      <label className="block text-base font-semibold text-gray-600">
        {props.label}
        {props.required && <span className={'text-red-500'}>*</span>}
      </label>
      <span className={'text-red-500 font-bold'}>{props.errorText}</span>
      <div className="relative rounded-md shadow-sm">
        <input
          type={props.type || 'text'}
          className={`
            block w-full px-3 py-2 border rounded-md text-base focus:outline-none focus:border-blue-500
            ${props.validation === '' && 'border-gray-300'}
            ${props.validation === 'error' && 'border-red-400'}
            ${props.validation === 'success' && 'border-green-400'}
            `}
          placeholder={props.placeholder || ''}
          autoComplete={props.autoComplete || ''}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      </div>
    </div>
  );
}

export default InputText;
