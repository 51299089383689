import React, { useEffect, useState } from 'react';
import { Alert, ConfigProvider, Layout, Menu, Modal, Spin } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import {
  ContainerOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  LoadingOutlined,
  PercentageOutlined,
  ReadOutlined,
  SmileOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import DashboardHeader from './Dashboard/DashboardHeader';
import Overview from './Dashboard/Overview';
import axiosApiInstance, { apiHeaders, resign } from '../Service/API';
import { EventSourcePolyfill as EventSource } from 'event-source-polyfill';
import Subscriptions from './Subscriptions/Subscriptions';
import SubscriptionsSuccess from './Subscriptions/SubscriptionsSuccess';
import Orders from './Orders/Orders';
import { Link, Route, Router, Switch, useRouteMatch } from 'react-router-dom';
import UserSettings from './Dashboard/UserSettings';
import MenuCards from './MenuCards/MenuCards';
import CategoriesItems from './MenuCards/CategoriesItems';
import Onboarding from './Dashboard/Onboarding';
import PaymentMethods from './PaymentMethods';
import PayPalCallback from './PayPalCallback';
import Drivers from './Drivers/Drivers';
import Chatwoot from '../Utils/Chatwoot';
import Env from '../Service/Env';
import useSound from 'use-sound';

const { Sider, Content, Footer } = Layout;

function Dashboard({ history }) {
  let { url, path } = useRouteMatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState('');
  const [user, setUser] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [sseStatus, setSseStatus] = useState(false);
  const [eventSource, setEventSource] = useState(null);
  const [menuDisabled, setMenuDisabled] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [newOrder, setNewOrder] = useState(null);
  const [newOrderAlertModal, setNewOrderAlertModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderUuids, setOrderUuids] = useState([]);

  const [playSound] = useSound('/sounds/chime_done.wav');

  const connectSse = () => {
    if (eventSource) disconnectSse();

    const es = new EventSource(`${axiosApiInstance.defaults.baseURL}/sse`, {
      headers: apiHeaders(),
    });

    es.onopen = () => {
      setSseStatus(true);
    };

    es.onerror = (evt) => {
      if (evt.status === 401) {
        disconnectSse();
        resign().then(() => connectSse());
      }
      setSseStatus(false);
    };

    // Listen for order updates
    es.addEventListener('order.update', (evt) => {
      const data = JSON.parse(evt.data);
      setNewOrder(data.order);
    });

    setEventSource(es);
  };

  const disconnectSse = () => {
    if (!eventSource) return;
    eventSource.close();
    eventSource.removeEventListener();
    setEventSource(null);
    setSseStatus(false);
  };

  const changeSelectedKeys = () => {
    let selectedKeysStorage = history.location?.pathname.split('/')[2];
    if (selectedKeysStorage) {
      setSelectedKeys(selectedKeysStorage);
    } else {
      setSelectedKeys('overview');
    }
  };

  const getOrders = () => {
    axiosApiInstance.get('/orders/self').then((response) => {
      setOrders(response.data);
      let allOrderUuids = [];
      response.data.forEach((order) => {
        allOrderUuids.push(order.uuid);
      });
      setOrderUuids(allOrderUuids);
    });
  };

  useEffect(() => {
    if (newOrder) {
      let tmpOrders = [...orders];
      if (!orderUuids.includes(newOrder.uuid)) {
        // Add new order
        setNewOrderAlertModal(true);
        setOrders([newOrder, ...tmpOrders]);
        setOrderUuids([newOrder.uuid, ...orderUuids]);
      } else {
        getOrders();
      }
      setNewOrder(null);
    }
  }, [newOrder]);

  useEffect(() => {
    if (!newOrderAlertModal) return;

    playSound();
    const interval = setInterval(() => {
      playSound();
    }, 3000);

    return () => clearInterval(interval);
  }, [newOrderAlertModal]);

  useEffect(() => {
    if (user) return;
    changeSelectedKeys();

    axiosApiInstance
      .get('/users/self')
      .then((res) => {
        setUser(res.data);
        if (user?.tenants.length === 0) {
          setMenuDisabled(true);
          history.push(`${path}/onboarding`);
        } else if (res.data['tenants'].length > 0) {
          setTenant(res.data['tenants'][0]);
          localStorage.setItem('tenantsUUID', res.data['tenants'][0].uuid);
          connectSse();
        }
        axiosApiInstance.get('/restaurants/self').then((res) => {
          if (res.data.length > 0) setRestaurant(res.data[0]);
        });
      })
      .finally(() => {
        setLoadingUserData(false);
      });
  }, []);

  return (
    <ConfigProvider locale={deDE}>
      <Router history={history}>
        <Spin indicator={antIcon} spinning={loadingUserData}>
          <Layout style={{ minHeight: '100vh' }}>
            <Sider
              collapsible
              collapsed={sideMenuCollapsed}
              onCollapse={() => setSideMenuCollapsed(!sideMenuCollapsed)}
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'sticky',
                top: 0,
                left: 0,
              }}
            >
              <div
                style={{
                  margin: '14px',
                  padding: '5px',
                  background: 'rgba(255, 255, 255, 0.2)',
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                {sideMenuCollapsed ? <SmileOutlined /> : 'schmeckn'}
              </div>
              <Menu
                theme="dark"
                mode="inline"
                selectedKeys={selectedKeys}
                onClick={changeSelectedKeys}
              >
                <Menu.Item
                  key="overview"
                  icon={<DashboardOutlined />}
                  disabled={menuDisabled}
                >
                  <Link to="/dashboard/overview">Übersicht</Link>
                </Menu.Item>
                <Menu.Item
                  key="orders"
                  icon={<ContainerOutlined />}
                  disabled={menuDisabled}
                >
                  <Link to={`${url}/orders`}>Bestellungen</Link>
                </Menu.Item>
                <Menu.Item
                  key="subscriptions"
                  icon={<PercentageOutlined />}
                  disabled={true}
                >
                  <Link to={`${url}/subscriptions`}>Abonnements</Link>
                </Menu.Item>
                <Menu.Item
                  key="menu-cards"
                  icon={<ReadOutlined />}
                  disabled={menuDisabled}
                >
                  <Link to={`${url}/menu-cards`}>Speisekarten</Link>
                </Menu.Item>
                <Menu.Item
                  key="payment-methods"
                  icon={<CreditCardOutlined />}
                  disabled={menuDisabled}
                >
                  <Link to={`${url}/payment-methods`}>Zahlungsarten</Link>
                </Menu.Item>
                {Env.FEATURE_DRIVER_MANAGEMENT && (
                  <Menu.Item
                    key="drivers"
                    icon={<UserSwitchOutlined />}
                    disabled={menuDisabled}
                  >
                    <Link to={`${url}/drivers`}>Fahrer-Management</Link>
                  </Menu.Item>
                )}
              </Menu>
            </Sider>
            <Layout>
              <DashboardHeader
                user={user}
                disconnectSse={disconnectSse}
                sseStatus={sseStatus}
                restaurant={restaurant}
                setRestaurant={setRestaurant}
              />
              <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                {user && tenant && (
                  <Chatwoot
                    websiteToken={Env.CHATWOOT_WEBSITE_TOKEN}
                    settings={{
                      type: 'expanded_bubble',
                      launcherTitle: 'Hilfe',
                    }}
                    handleIdentityValidation={true}
                    userIdentifier={user.uuid}
                    user={{
                      name: user.name,
                      email: user.email,
                      company_name: tenant.name,
                    }}
                  />
                )}
                {restaurant && !restaurant.canAcceptOrders && (
                  <div style={{ marginBottom: '16px' }}>
                    <Alert
                      showIcon
                      message="Das annehmen von Bestellungen ist derzeit deaktiviert!"
                      type="error"
                    />
                  </div>
                )}
                <Switch>
                  <Route exact path={`${path}/onboarding`}>
                    <Onboarding user={user} history={history} />
                  </Route>
                  <Route exact path={`${path}/overview`}>
                    <Overview />
                  </Route>
                  <Route path={`${path}/orders`}>
                    {localStorage.getItem('tenantsUUID') && (
                      <Orders
                        orders={orders}
                        setOrders={setOrders}
                        orderUuids={orderUuids}
                        setOrderUuids={setOrderUuids}
                        getOrders={getOrders}
                      />
                    )}
                  </Route>
                  <Route exact path={`${path}/subscriptions`}>
                    <Subscriptions user={user} />
                  </Route>
                  <Route path={`${path}/tags`}>{/*<TagsContainer/>*/}</Route>
                  <Route path={`${path}/categoriesandmeals`}>
                    <CategoriesItems />
                  </Route>
                  <Route path={`${path}/menu-cards`}>
                    <MenuCards restaurant={restaurant} />
                  </Route>
                  <Route path={`/dashboard/settings`}>
                    <UserSettings user={user} />
                  </Route>
                  <Route exact path={`/dashboard/subscriptions/success`}>
                    <SubscriptionsSuccess />
                  </Route>
                  <Route exact path={`${path}/payment-methods`}>
                    <PaymentMethods
                      restaurant={restaurant}
                      setRestaurant={setRestaurant}
                      tenant={user?.tenants[0]}
                    />
                  </Route>
                  <Route exact path={`${path}/payment-methods/paypal/callback`}>
                    <PayPalCallback />
                  </Route>
                  {Env.FEATURE_DRIVER_MANAGEMENT && (
                    <Route exact path={`${path}/drivers`}>
                      <Drivers restaurant={restaurant} />
                    </Route>
                  )}
                </Switch>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                <div className={'pb-1'}>
                  <button onClick={() => window.klaro.show()}>
                    Cookie Einstellungen
                  </button>
                </div>
                Made with <span style={{ color: '#e25555' }}>&#9829;</span> in
                Germany by schmeckn © {new Date().getFullYear()}
              </Footer>
              <Modal
                title="Neue Bestellung"
                visible={newOrderAlertModal}
                onOk={() => setNewOrderAlertModal(false)}
                okText={'Verstanden'}
                closable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
              >
                <h1>Du hast eine neue Bestellung erhalten!</h1>
              </Modal>
            </Layout>
          </Layout>
        </Spin>
      </Router>
    </ConfigProvider>
  );
}

export default Dashboard;
