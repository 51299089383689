import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Env from '../Service/Env';

export default function GmapsInput(props) {
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [scriptId, setScriptId] = useState(null);

  const getScript = () => {
    return document.getElementById(`${scriptId}`);
  };

  const hasScript = () => {
    if (!scriptId) return false;
    return !!getScript();
  };

  const addScript = () => {
    if (hasScript()) return;
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${Env.GOOGLE_API_KEY}&libraries=places`;
    script.defer = true;
    script.async = true;

    script.id = btoa(`${Date.now()}.${script.src}`);
    setScriptId(script.id);

    script.onload = () => {
      setMapsLoaded(true);
    };

    head.appendChild(script);
  };

  useEffect(() => {
    addScript();
  }, []);

  useEffect(() => {
    props.validation === '' && props.setValidationBorderColor('#d1d5db');
    props.validation === 'error' && props.setValidationBorderColor('#f87171');
    props.validation === 'success' && props.setValidationBorderColor('#4ade80');
  }, [props.validation]);

  return (
    <>
      {mapsLoaded && (
        <GooglePlacesAutocomplete
          selectProps={{
            defaultInputValue: props.initialValue,
            value: props.value,
            onChange: props.onChange,
            onBlur: props.onBlur,
            placeholder: props.placeholder || '',
            noOptionsMessage: () => 'Bitte eine gültige Adresse eingeben!',
            loadingMessage: () => 'Einen Moment bitte...',
            styles: props.style,
          }}
          // autocompletionRequest={{
          //   types: ['street_address'],
          //   componentRestrictions: {
          //     country: ['de'],
          //   },
          // }}
        />
      )}
    </>
  );
}
