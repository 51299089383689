export const orderStateNameTranslations = {
  pending: 'Ausstehend',
  open: 'Offen',
  'in-progress': 'In Bearbeitung',
  ready: 'Bereit',
  'in-delivery': 'In Auslieferung',
  done: 'Abgeschlossen',
  canceled: 'Abgebrochen',
};

export const paymentMethodTranslations = {
  paypal: 'PayPal',
  stripe: 'Stripe',
  cash: 'Bar',
};

export const deliveryMethodTranslations = {
  delivery: 'Lieferung',
  pickup: 'Abholung',
};

export const dayNameTranslation = {
  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  sunday: 'Sonntag',
};

const translate = (map, value) => {
  return map[value] || value;
};

const translator = {
  orderStateName: (orderStateName) =>
    translate(orderStateNameTranslations, orderStateName),
  paymentMethod: (paymentMethod) =>
    translate(paymentMethodTranslations, paymentMethod),
  deliveryMethod: (deliveryMethod) =>
    translate(deliveryMethodTranslations, deliveryMethod),
  dayName: (dayName) => translate(dayNameTranslation, dayName),
};

export default translator;
