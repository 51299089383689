import {
  Card,
  Col,
  Collapse,
  Divider,
  Result,
  Row,
  Skeleton,
  Switch,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axiosApiInstance from '../Service/API';
import { OpenAccountButton } from './Utils/Stripe';
import Env from '../Service/Env';

const { Panel } = Collapse;

function PaymentMethod({
  restaurant,
  setRestaurant,
  paymentMethod,
  displayPaymentMethod,
  children,
}) {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const paymentMethods = restaurant?.paymentMethods || [];
    setActive(paymentMethods.includes(paymentMethod));
  }, [restaurant]);

  const updatePaymentMethod = (shouldBeActive) => {
    setActive(shouldBeActive);
    let paymentMethods = restaurant?.paymentMethods || [];
    paymentMethods = shouldBeActive
      ? [...paymentMethods, paymentMethod]
      : paymentMethods.filter((pm) => pm !== paymentMethod);
    return axiosApiInstance
      .patch(`/restaurants/${restaurant.uuid}`, {
        paymentMethods: paymentMethods,
      })
      .then((res) => {
        if (res.status === 200) {
          setRestaurant(res.data);
        }
      });
  };

  return (
    <Card
      title={
        <>
          <span style={{ marginRight: '0.5rem' }}>{displayPaymentMethod}</span>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={isActive}
            onClick={updatePaymentMethod}
          />
        </>
      }
      style={{ height: '100%' }}
    >
      {children}
    </Card>
  );
}

function PayPalPaymentMethod({ restaurant, tenant, setRestaurant }) {
  const [scriptId, setScriptId] = useState(null);

  const getScript = () => {
    return document.getElementById(`${scriptId}`);
  };

  const hasScript = () => {
    if (!scriptId) return false;
    return !!getScript();
  };

  const addScript = () => {
    if (hasScript()) return;
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.src = `https://www.paypalobjects.com/js/external/api.js`;
    script.defer = true;
    script.async = true;

    script.id = btoa(`${Date.now()}.${script.src}`);
    setScriptId(script.id);

    script.onload = () => {
      window.paypal.use(['login'], (login) => {
        login.render({
          appid: Env.PAYPAL_CLIENT_ID,
          authend: Env.PAYPAL_ENV,
          scopes: 'email',
          containerid: 'paypalLoginButton',
          responseType: 'code',
          locale: 'de-de',
          buttonType: 'CWP',
          buttonShape: 'pill',
          buttonSize: 'lg',
          fullPage: 'false',
          returnurl: `${window.location.origin}/dashboard/payment-methods/paypal/callback`,
        });
      });
    };

    head.appendChild(script);
  };

  useEffect(() => {
    addScript();
  }, []);

  return (
    <PaymentMethod
      restaurant={restaurant}
      paymentMethod="paypal"
      displayPaymentMethod="PayPal"
      setRestaurant={setRestaurant}
    >
      <span id="paypalLoginButton" style={{ width: '50em' }} />
      {tenant?.paypal?.email && (
        <>
          <Divider />
          <Result
            status="success"
            title="PayPal sieht gut aus!"
            subTitle={
              <span>
                PayPal ist erfolgreich eingerichtet. Der PayPal-Account mit der
                <br />
                E-Mail Adresse <b>{tenant.paypal.email}</b>
                <br />
                ist verbunden.
              </span>
            }
          />
        </>
      )}
    </PaymentMethod>
  );
}

function StripePaymentMethod({ restaurant, setRestaurant }) {
  const [isLoading, setLoading] = useState(true);
  const [hints, setHints] = useState([]);

  useEffect(() => {
    axiosApiInstance
      .get('/stripe/seller/account/state')
      .then((res) => {
        const { chargesEnabled, payoutsEnabled } = res.data;
        const hints = [];

        if (!chargesEnabled)
          hints.push({
            title: 'Unternehmensinformationen werden benötigt',
            text: 'Diese Informationen werden benötigt damit schmeckn Zahlungen auf ihrere Seite für ihre Kunden tätigen kann.',
          });

        if (!payoutsEnabled)
          hints.push({
            title: 'Auszahlungskonto nicht festgelegt',
            text: 'Eine valide Bankverbindung wird benötigt um eingegangene Zahlungen von schmeckn auf ihr Konto zu überweisen.',
          });

        setHints(hints);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <PaymentMethod
      restaurant={restaurant}
      paymentMethod="stripe"
      displayPaymentMethod="Stripe"
      setRestaurant={setRestaurant}
    >
      <OpenAccountButton />
      <Divider />
      <Skeleton loading={isLoading}>
        {hints.length > 0 ? (
          <Collapse>
            {hints.map((hint, i) => (
              <Panel header={hint.title} key={i}>
                <p>{hint.text}</p>
              </Panel>
            ))}
          </Collapse>
        ) : (
          <Result
            status="success"
            title="Stripe ist startklar!"
            subTitle="Stripe ist erfolgreich eingerichtet."
          />
        )}
      </Skeleton>
    </PaymentMethod>
  );
}

function PaymentMethods({ restaurant, tenant, setRestaurant }) {
  return (
    <Card title="Zahlungsarten">
      <Row>
        <Col span={12}>
          <PayPalPaymentMethod
            restaurant={restaurant}
            tenant={tenant}
            setRestaurant={setRestaurant}
          />
        </Col>
        <Col span={12}>
          <StripePaymentMethod
            restaurant={restaurant}
            setRestaurant={setRestaurant}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default PaymentMethods;
