import axios from 'axios';
import Env from './Env';

export const apiHeaders = () => ({
  ...(Env.BASIC_AUTH_HEADER ? { Authorization: Env.BASIC_AUTH_HEADER } : {}),
  'X-Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
  'X-Tenant': localStorage.getItem('tenantsUUID'),
});

const axiosApiInstance = axios.create({
  baseURL: Env.API_BASE_URL,
  withCredentials: Env.WITH_CREDENTIALS,
});

export const resign = () => {
  return axiosApiInstance
    .post('/auth/resign', {
      refreshToken: localStorage.getItem('refreshToken'),
    })
    .then((response) => {
      if (!response.data?.accessToken)
        throw new Error('Resign does not resolves in accessToken');

      localStorage.setItem('accessToken', response.data.accessToken);

      // set fresh accessToken for later requests
      axios.defaults.headers[
        'X-Authorization'
      ] = `Bearer ${response.data.accessToken}`;

      return response;
    })
    .catch((error) => {
      logout();
      throw error;
    });
};

axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = apiHeaders();
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url !== '/auth/resign' &&
      !originalRequest._triedToRefreshAccessToken
    ) {
      originalRequest._triedToRefreshAccessToken = true;
      return resign().then(() => {
        // if original request had a json payload, try to parse it
        if (originalRequest.headers['Content-Type'] === 'application/json')
          originalRequest.data = JSON.parse(originalRequest.data);

        // return original request to retry with refreshed accessToken
        return axiosApiInstance(originalRequest);
      });
    }

    throw error;
  },
);

export const logout = (reason = 'session') => {
  if (window.$chatwoot) window.$chatwoot.reset();
  window.location = `/login?logout=${reason}`;
  window.reload();
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tenantsUUID');
};

export default axiosApiInstance;
