import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Translation from '../../Service/Translation';

function Information(props) {
  const [paymentMethods, setPaymentMethods] = useState('');

  useEffect(() => {
    let tmpPaymentMethods = [];
    props.restaurant.paymentMethods.includes('cash') &&
      tmpPaymentMethods.push('Bar');
    props.restaurant.paymentMethods.includes('paypal') &&
      tmpPaymentMethods.push('PayPal');
    props.restaurant.paymentMethods.includes('stripe') &&
      tmpPaymentMethods.push(
        'Kreditkarte',
        'Klarna',
        'Apple Pay',
        'Google Pay',
      );
    setPaymentMethods(tmpPaymentMethods.join(', '));
  }, [props.restaurant.paymentMethods]);

  return (
    <>
      <div className="h-auto p-4 border-b bg-white overflow-auto">
        <div className={'main grid grid-cols-4 grid-flow-col gap-x-4 px-2'}>
          <div className={'col-span-2 row-span-1'}>
            <div className={'border-b text-base font-bold tracking-wider'}>
              Adresse
            </div>
            <div className={'font-medium pt-1 pr-2'}>
              {props.restaurant.name}
            </div>
            <div className={'font-medium pr-2'}>
              {props.restaurant.address.street}
            </div>
            <div className={'font-medium pr-2'}>
              {props.restaurant.address.postalCode}{' '}
              {props.restaurant.address.city}
            </div>
          </div>
          <div className={'col-span-2 row-span-1'}>
            <div className={'border-b text-base font-bold tracking-wider flex'}>
              Zahlungsmethoden
            </div>
            <div className={'font-medium pt-1 pr-2'}>{paymentMethods}</div>
          </div>
          <div className={'col-span-2 row-span-2'}>
            <div className={'border-b text-base font-bold tracking-wider'}>
              Öffnungszeiten
            </div>
            <div>
              {props.restaurant.openingHours.map((data) => (
                <div
                  className={'font-medium pt-1 pr-2 space-y-2'}
                  key={data.day}
                >
                  <div className={'leading-tight'}>
                    <span className={'block md:inline'}>
                      {Translation.dayName(data.day)}:{' '}
                    </span>
                    <span
                      className={`${
                        moment(moment.now()).format('dddd') ===
                          Translation.dayName(data.day) && 'font-bold'
                      }`}
                    >
                      {data.open === '0000' && data.close === '0000'
                        ? 'Geschlossen'
                        : `${moment(data.open, 'HHmm').format(
                            'HH:mm',
                          )}Uhr - ${moment(data.close, 'HHmm').format(
                            'HH:mm',
                          )}Uhr`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Information;
