import React, { useState } from 'react';
import CartContainer from './Cart/CartContainer';
import UserDataContainer from './UserData/UserDataContainer';
import PaymentContainer from './Payment/PaymentContainer';
import { validateEmail, validatePhone } from '../../Service/RegexValidation';

function CheckoutContainer(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [validation, setValidation] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    formattedAddress: '',
  });

  const validateOnBlur = (field) => {
    let tmpValidation = { ...validation };
    if (field === 'email') {
      validateEmail.test(props.checkoutFormFields.email)
        ? (tmpValidation.email = 'success')
        : (tmpValidation.email = 'error');
    } else if (field === 'phone') {
      validatePhone.test(props.checkoutFormFields.phone)
        ? (tmpValidation.phone = 'success')
        : (tmpValidation.phone = 'error');
    } else if (field === 'firstName') {
      props.checkoutFormFields.firstName !== ''
        ? (tmpValidation.firstName = 'success')
        : (tmpValidation.firstName = 'error');
    } else if (field === 'lastName') {
      props.checkoutFormFields.lastName !== ''
        ? (tmpValidation.lastName = 'success')
        : (tmpValidation.lastName = 'error');
    } else if (field === 'formattedAddress') {
      props.checkoutFormFields.formattedAddress !== ''
        ? (tmpValidation.formattedAddress = 'success')
        : (tmpValidation.formattedAddress = 'error');
    }
    setValidation(tmpValidation);
  };

  const validateOnNextClick = () => {
    let tmpValidation = { ...validation };
    validateEmail.test(props.checkoutFormFields.email)
      ? (tmpValidation.email = 'success')
      : (tmpValidation.email = 'error');
    validatePhone.test(props.checkoutFormFields.phone)
      ? (tmpValidation.phone = 'success')
      : (tmpValidation.phone = 'error');
    props.checkoutFormFields.firstName !== ''
      ? (tmpValidation.firstName = 'success')
      : (tmpValidation.firstName = 'error');
    props.checkoutFormFields.lastName !== ''
      ? (tmpValidation.lastName = 'success')
      : (tmpValidation.lastName = 'error');
    props.checkoutFormFields.formattedAddress !== ''
      ? (tmpValidation.formattedAddress = 'success')
      : (tmpValidation.formattedAddress = 'error');
    setValidation(tmpValidation);

    let validationCounter = 0;
    for (const [_, value] of Object.entries(tmpValidation)) {
      if (value === 'success') {
        validationCounter += 1;
      }
    }

    return validationCounter === 5 && true;
  };

  const changeStep = (direction) => {
    let tmpCurrentStep = currentStep;
    if (direction === 'left') {
      if (tmpCurrentStep > 0) {
        tmpCurrentStep -= 1;
      }
    } else if (direction === 'right') {
      if (tmpCurrentStep < 2) {
        if (currentStep === 1) {
          if (validateOnNextClick()) tmpCurrentStep += 1;
        } else {
          tmpCurrentStep += 1;
        }
      }
    }
    setCurrentStep(tmpCurrentStep);
  };

  return (
    <div className="w-full">
      <div className="text-gray-700 font-medium text-center text-xl tracking-wider font-bold">
        Fast geschafft 🎉
      </div>

      <div className="flex mt-6">
        <div className="w-1/3">
          <div className="relative mb-2">
            <div className="w-10 h-10 mx-auto bg-schmeckn-normal rounded-full text-lg text-white flex items-center">
              <span className="text-center text-white w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="text-base text-center font-semibold">Warenkorb</div>
        </div>

        <div className="w-1/3">
          <div className="relative mb-2">
            <div
              className="absolute flex align-center items-center align-middle content-center"
              style={{
                width: 'calc(100% - 2.5rem - 1rem)',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className="w-0 bg-schmeckn-normal py-1 rounded"
                  style={{ width: `${currentStep > 0 ? '100%' : '0%'}` }}
                />
              </div>
            </div>

            <div
              className={`w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center ${
                currentStep < 1
                  ? 'bg-white border-2 border-gray-200'
                  : 'bg-schmeckn-normal'
              }`}
            >
              <span
                className={`text-center w-full ${
                  currentStep < 1 ? 'text-gray-600' : 'text-white'
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="text-base text-center font-semibold">Daten</div>
        </div>

        <div className="w-1/3">
          <div className="relative mb-2">
            <div
              className="absolute flex align-center items-center align-middle content-center"
              style={{
                width: 'calc(100% - 2.5rem - 1rem)',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className="w-0 bg-schmeckn-normal py-1 rounded"
                  style={{ width: `${currentStep >= 2 ? '100%' : '0%'}` }}
                />
              </div>
            </div>

            <div
              className={`w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center ${
                currentStep < 2
                  ? 'bg-white border-2 border-gray-200'
                  : 'bg-schmeckn-normal'
              }`}
            >
              <span
                className={`text-center w-full ${
                  currentStep < 2 ? 'text-gray-600' : 'text-white'
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="text-base text-center font-semibold">Abschließen</div>
        </div>
      </div>

      {currentStep === 0 && (
        <CartContainer
          basket={props.basket}
          originalItems={props.originalItems}
          changeBasket={props.changeBasket}
          setModalState={props.setModalState}
        />
      )}
      {currentStep === 1 && (
        <UserDataContainer
          checkoutFormFields={props.checkoutFormFields}
          setCheckoutFormFields={props.setCheckoutFormFields}
          validateOnBlur={validateOnBlur}
          validation={validation}
        />
      )}
      {currentStep === 2 && (
        <PaymentContainer
          basket={props.basket}
          restaurant={props.restaurant}
          checkoutFormFields={props.checkoutFormFields}
          history={props.history}
        />
      )}

      <div className={'mt-6 flex justify-around'}>
        <button
          className={`text-white rounded-md py-1 px-4 ${
            currentStep !== 0 ? 'bg-schmeckn-normal' : 'hidden'
          }`}
          onClick={() => changeStep('left')}
        >
          <div className="flex justify-center flex-row items-center leading-6 text-white font-medium text-lg tracking-widest">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Zurück
          </div>
        </button>
        <button
          className={`text-white rounded-md py-1 px-4 bg-schmeckn-normal ${
            currentStep === 2 && 'hidden'
          }`}
          onClick={() => changeStep('right')}
        >
          <div className="flex justify-center flex-row items-center leading-6 text-white font-medium text-lg tracking-widest">
            Weiter
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

export default CheckoutContainer;
