import React, { useState, useEffect } from 'react';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import ItemModal from '../../Card/Item/ItemModal';
import { ConvertPriceAmount } from '../../../Service/Helper';

function CartItem(props) {
  let [isOpen, setIsOpen] = useState(false);
  let [radioExtraElems, setRadioExtraElems] = useState([]);
  let [checkboxExtraElems, setCheckboxExtraElems] = useState([]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    props.setModalState();
  };

  useEffect(() => {
    let radioExtraElems = [];
    let checkboxExtraElems = [];
    let lengthOfExtras = props.basketItem.customExtras.length - 1;
    props.basketItem.customExtras.forEach((extra, index) => {
      if (extra.menuExtraType === 'radio') {
        let elem;
        if (index < lengthOfExtras) {
          elem = <span key={`radio_${extra.name}`}>{extra.name}, </span>;
        } else {
          elem = <span key={`radio_${extra.name}`}>{extra.name}</span>;
        }
        radioExtraElems.push(elem);
      } else if (extra.menuExtraType === 'checkbox') {
        let elem;
        if (index < lengthOfExtras) {
          elem = <span key={`checkbox_${extra.name}`}>{extra.name}, </span>;
        } else {
          elem = <span key={`checkbox_${extra.name}`}>{extra.name}</span>;
        }
        checkboxExtraElems.push(elem);
      }
    });
    setRadioExtraElems(radioExtraElems);
    setCheckboxExtraElems(checkboxExtraElems);
  }, [props.basketItem]);

  return (
    <>
      <div className={'flex justify-start content-center items-center w-full'}>
        <span className={'w-6 mr-4 font-semibold text-base'}>
          {props.basketItem.pieces}x
        </span>
        <div className={'w-full flex-1 text-clip text-ellipsis mr-4'}>
          <span className={'text-base block font-semibold leading-tight'}>
            {props.basketItem.name}
          </span>
        </div>
        <div className={'flex justify-center mr-0.5 space-x-2 text-gray-600'}>
          <button>
            <PencilAltIcon
              className="h-5 w-5 icon-text-schmeckn-blue"
              onClick={() => toggleModal()}
            />
          </button>
          <button>
            <TrashIcon
              className="h-5 w-5 icon-text-schmeckn-blue"
              onClick={() => props.changeBasket(props.basketItem, 'delete')}
            />
          </button>
        </div>
        <span
          className={
            'whitespace-nowrap w-16 text-right text-base font-semibold text-gray-600'
          }
        >
          {ConvertPriceAmount(props.basketItem.finalPrice)}€
        </span>
      </div>
      <div className={'flex w-full mb-1 leading-none text-gray-500 text-xs'}>
        <span className={'w-6 mr-4'} />
        <div className={'w-3/6'}>
          {radioExtraElems}
          {checkboxExtraElems}
        </div>
      </div>
      {props.basketItem.note && (
        <div
          className={
            'flex w-full mb-1 leading-none text-gray-500 text-xs italic'
          }
        >
          <span className={'w-6 mr-4 '} />
          <div className={'w-3/6'}>
            <span>{props.basketItem.note}</span>
          </div>
        </div>
      )}
      <ItemModal
        isOpen={isOpen}
        item={props.basketItem}
        toggleModal={() => toggleModal()}
        changeBasket={props.changeBasket}
        operation={'edit'}
        canAcceptOrders={''}
      />
    </>
  );
}

export default CartItem;
