import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Switch as AntSwitch,
  Tag,
} from 'antd';
import axiosApiInstance from '../../Service/API';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import BackendModal from '../Utils/BackendModal';
import { useIdentity } from '../Utils/useIdentity';

export default function Drivers(props) {
  const { tenant } = useIdentity();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentDriver, setCurrentDriver] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getDrivers();
  }, [tenant]);

  const getDrivers = () => {
    axiosApiInstance.get('/drivers/self').then((response) => {
      let tmpDrivers = [];
      response.data.forEach((driver) => {
        tmpDrivers.push(
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}
            xxl={6}
            key={driver.uuid}
          >
            <Card>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {driver.name}
                <Tag
                  style={{ marginLeft: '10px' }}
                  color={driver.inviteAccepted ? 'green' : 'red'}
                >
                  {driver.inviteAccepted
                    ? 'E-Mail bestätigt'
                    : 'E-Mail nicht bestätigt'}
                </Tag>
                <Tag color={driver.isActive ? 'green' : 'red'}>
                  {driver.isActive ? 'Aktiv' : 'Inaktiv'}
                </Tag>
              </div>
              <div
                style={{
                  fontSize: '12px',
                  color: '#6b6b6b',
                  fontWeight: '400',
                  marginBottom: '1em',
                }}
              >
                {driver.email}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  style={{ marginRight: '0.5em' }}
                  icon={<EditOutlined />}
                  size={'small'}
                  type="primary"
                  onClick={() => {
                    setEditMode(true);
                    setCurrentDriver(driver);
                    form.resetFields();
                    setModalVisible(true);
                  }}
                >
                  Bearbeiten
                </Button>
                <Popconfirm
                  title={`Fahrer wirklich löschen?`}
                  onConfirm={() => {
                    axiosApiInstance
                      .delete(`/drivers/${driver.uuid}`)
                      .then((response) => {
                        if (response.status === 200) {
                          getDrivers();
                          message.success('Fahrer erfolgreich gelöscht!');
                        } else {
                          message.error('Fahrer löschen fehlgeschlagen!');
                        }
                      });
                  }}
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Button
                    type="primary"
                    danger
                    size={'small'}
                    icon={<DeleteOutlined />}
                  >
                    Löschen
                  </Button>
                </Popconfirm>
              </div>
            </Card>
          </Col>,
        );
      });
      setDrivers(tmpDrivers);
      setLoading(false);
    });
  };

  const toggleModal = () => {
    form.resetFields();
    setModalVisible(!modalVisible);
  };

  return (
    <>
      <Card title="Fahrer-Management" loading={loading}>
        <Button
          size="small"
          style={{ marginBottom: '15px' }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            toggleModal();
            setEditMode(false);
          }}
        >
          Neuen Fahrer hinzufügen
        </Button>
        <Row gutter={[16, 16]}>{drivers}</Row>
      </Card>
      <BackendModal
        name={'Fahrer'}
        editMode={editMode}
        toggleModal={toggleModal}
        modalVisible={modalVisible}
        okText={`${!editMode ? 'Hinzufügen' : 'Speichern'}`}
        onOk={() => {
          if (!editMode) {
            form
              .validateFields()
              .then((values) => {
                values['restaurant'] = { uuid: props.restaurant.uuid };
                axiosApiInstance.post('/drivers', values).then((response) => {
                  if (response.status === 201) {
                    toggleModal();
                    getDrivers();
                    message.success('Fahrer erfolgreich eingeladen');

                    form.resetFields();
                  } else {
                    message.error('Fahrer einladen fehlgeschlagen');
                  }
                });
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          } else {
            form
              .validateFields()
              .then((values) => {
                // delete values.isActive
                axiosApiInstance
                  .patch(`/drivers/${currentDriver.uuid}`, values)
                  .then((response) => {
                    if (response.status === 200) {
                      message.success('Fahrer erfolgreich geändert');
                      getDrivers();
                      toggleModal();
                      form.resetFields();
                    } else {
                      message.error('Fahrer ändern fehlgeschlagen');
                    }
                  });
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }
        }}
        content={
          <>
            <Form
              form={form}
              layout="vertical"
              fields={[
                { name: 'name', value: editMode ? currentDriver.name : '' },
                { name: 'email', value: editMode ? currentDriver.email : '' },
                {
                  name: 'isActive',
                  value: editMode ? currentDriver.isActive : '',
                },
              ]}
            >
              <Form.Item
                label="Name"
                name="name"
                hasFeedback
                rules={[
                  { required: true, message: 'Bitte einen Namen eingeben' },
                ]}
                tooltip={{
                  title: <div>Name des Fahrers</div>,
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input placeholder="Max Mustermann" />
              </Form.Item>
              <Form.Item
                label="E-Mail"
                name="email"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Bitte eine E-Mail Adresse eingeben',
                  },
                ]}
                tooltip={{
                  title: (
                    <div>
                      An diese E-Mail werden später die Bestellungen gesendet,{' '}
                      <br /> welche ausgeliefert werden sollen.
                    </div>
                  ),
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input placeholder="max-mustermann@web.de" />
              </Form.Item>
              {editMode && (
                <Form.Item name="isActive" valuePropName="checked">
                  <AntSwitch
                    checkedChildren="Aktiv"
                    unCheckedChildren="Inaktiv"
                  />
                </Form.Item>
              )}
            </Form>
          </>
        }
      />
    </>
  );
}
