import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import { HashLink } from 'react-router-hash-link';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useRouteMatch } from 'react-router-dom';

function CategoryNavbar(props) {
  const container = useRef(null);
  let { url } = useRouteMatch();

  return (
    <>
      <nav className="border-b">
        <div className={'flex justify-between'}>
          <button
            className={'mx-2 pl-2'}
            onClick={() =>
              (ReactDOM.findDOMNode(container.current).scrollLeft += -50)
            }
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          <ScrollContainer ref={container} style={{ scrollBehavior: 'smooth' }}>
            <div className="flex">
              {props?.categories?.map((category, index) => (
                <div key={index} className="px-4 py-2 flex-shrink-0 border-r">
                  <HashLink
                    to={`${url}/#${category.name.replace(' ', '_')}`}
                    className={
                      'text-gray-900 hover:text-schmeckn-normal font-semibold'
                    }
                    smooth
                  >
                    {category.name}
                  </HashLink>
                </div>
              ))}
            </div>
          </ScrollContainer>
          <button
            className={'mx-2 pr-2'}
            onClick={() =>
              (ReactDOM.findDOMNode(container.current).scrollLeft += 50)
            }
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      </nav>
    </>
  );
}

export default CategoryNavbar;
