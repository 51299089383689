import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import MenuContainer from './Components/Menu/MenuContainer';
import Dashboard from './Components/Backend/Dashboard';
import Login from './Components/Backend/Login';
import PrivateRoute from './Components/Backend/Utils/ProtectedRoute';
// import Base from "./Components/LandingPage/Base";
import RestaurantLandingPage from './Components/RestaurantLP/RestaurantLandingPage';
import EmailVerification from './Components/Backend/EmailVerification';
import Restaurant404 from './Components/Generic/Restaurant404';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Env from './Components/Service/Env';
import useCookieConsent from './Components/Service/UseCookieConsent';
import { ProvideIdentity } from './Components/Backend/Utils/useIdentity';

if (Env.SENTRY_ENABLE) {
  Sentry.init({
    dsn: Env.SENTRY_DSN,
    environment: Env.SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: Env.SENTRY_ENVIRONMENT === 'production' ? 0.6 : 1.0,
  });
}

function App() {
  const history = useHistory();
  useCookieConsent();

  useEffect(() => {
    setViewHeight();
    window.addEventListener('resize', () => {
      setViewHeight();
    });
  });

  const setViewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  return (
    <Switch>
      <Route exact path="/">
        {/*<Base />*/}
      </Route>
      <Route path="/restaurant_not_found">
        <Restaurant404 />
      </Route>
      <Route path={`/restaurant/:restaurant`}>
        <RestaurantLandingPage history={history} />
      </Route>
      <Route path={`/speisekarte/:menuCard`}>
        <MenuContainer history={history} />
      </Route>
      <Route path="/login">
        <Login history={history} />
      </Route>
      <Route path="/emailverification">
        <EmailVerification history={history} />
      </Route>
      <Route path="/acceptDriverInvite">
        <EmailVerification history={history} />
      </Route>
      <Route path="*">
        <ProvideIdentity>
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            history={history}
          />
        </ProvideIdentity>
      </Route>
    </Switch>
  );
}

export default Sentry.withProfiler(App);
