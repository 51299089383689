import React, { useState } from 'react';
import { Button, Card, Form, Input, message, Result, Spin, Steps } from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import axiosApiInstance from '../../Service/API';
import GmapsInput from '../../Utils/GmapsInput';

function WelcomeStep() {
  return (
    <>
      <Title align="center">Willkommen bei schmeckn!</Title>
      <Paragraph ellipsis={true} align="center">
        Mithilfe unseres Onboardings legst du dein erstes Restaurant an und
        finalisierst damit deinen Account,
        <br />
        danach bist du bereit für schmeckn.
      </Paragraph>
    </>
  );
}

function CreateRestaurantStep({ displayStepsActionsOnParent, nextStep }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({
    formattedAddress: '',
    placeId: '',
  });

  displayStepsActionsOnParent(false);

  const onFinish = async (formData) => {
    setLoading(true);

    try {
      const data = {
        restaurant: {
          name: formData.name,
          description: formData.description,
          placeId: address.placeId,
          deliveryRange: 10000,
        },
      };

      const response = await axiosApiInstance.put('/tenants/onboarding', data);
      localStorage.setItem('tenantsUUID', response.data.uuid);

      setLoading(false);
      nextStep();
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  return (
    <>
      <Title level={3}>Dein erstes Restaurant bei schmeckn</Title>
      <Spin tip="Restaurant wird angelegt ..." spinning={loading}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Adresse"
            name="placeId"
            rules={[
              {
                required: true,
                message: 'Du benötigst eine Adresse für dein Restaurant',
              },
            ]}
          >
            <GmapsInput
              value={address.formattedAddress}
              placeholder={'Deine Adresse'}
              onChange={(e) => {
                setAddress({
                  formattedAddress: e.value.description,
                  placeId: e.value.place_id,
                });
              }}
              style={{
                container: (provided) => ({
                  ...provided,
                  position: 'relative',
                  margin: 0,
                  padding: 0,
                }),
                control: () => ({
                  display: 'flex',
                  borderWidth: '1px',
                  borderRadius: '',
                  fontSize: '14px',
                  lineHeight: '1.5rem',
                  borderColor: '#d9d9d9',
                  padding: '4px 11px',
                }),
                placeholder: () => ({
                  color: '#9ca3af',
                }),
                indicatorsContainer: () => ({
                  display: 'none',
                }),
                input: () => ({
                  margin: 0,
                  padding: 0,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  margin: 0,
                  padding: 0,
                }),
              }}
            />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Du benötigst einen Namen für dein Restaurant',
              },
            ]}
          >
            <Input placeholder="Mein Restaurant" />
          </Form.Item>
          <Form.Item
            label="Beschreibung"
            name="description"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Du benötigst eine Beschreibung für dein Restaurant',
              },
            ]}
          >
            <Input placeholder="Hier schmeckt es besonder gut!" />
          </Form.Item>
          <Button htmlType="submit">Fertig!</Button>
        </Form>
      </Spin>
    </>
  );
}

function DoneStep({ displayStepsActionsOnParent, history }) {
  displayStepsActionsOnParent(false);

  const toDashboard = () => {
    history.push('/dashboard');
    window.location.reload();
  };

  return (
    <>
      <Result
        status="success"
        title="Lass es dir schmeckn!"
        subTitle="Unsere Systeme haben dein Restaurant und Account erfolgreich angelegt!"
        extra={[
          <Button type="primary" onClick={toDashboard}>
            Dashboard
          </Button>,
          <Button type="default">Fragen?</Button>,
        ]}
      ></Result>
    </>
  );
}

function Onboarding({ user, history }) {
  const steps = [
    {
      title: 'Willkommen',
      content: (props) => <WelcomeStep {...props} />,
    },
    {
      title: 'Restaurant anlegen',
      content: (props) => <CreateRestaurantStep {...props} />,
    },
    {
      title: 'Fertig',
      content: (props) => <DoneStep {...props} />,
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const defaultDisplayStepsActions = true;
  const [displayStepsActions, setDisplayStepsActions] = useState(
    defaultDisplayStepsActions,
  );

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    setDisplayStepsActions(defaultDisplayStepsActions);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
    setDisplayStepsActions(defaultDisplayStepsActions);
  };

  const card = (content) => <Card title="Onboarding">{content}</Card>;

  if (user?.tenants.length > 0)
    return card(
      <Title level={3}>Dein Onboarding ist bereits abgeschlossen!</Title>,
    );

  return card(
    <>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Steps.Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <div
        className="steps-content"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        {steps[currentStep].content({
          displayStepsActionsOnParent: (v) => setDisplayStepsActions(v),
          nextStep,
          previousStep,
          history,
        })}
      </div>
      {displayStepsActions && (
        <div className="steps-action">
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => nextStep()}>
              Weiter
            </Button>
          )}
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => previousStep()}>
              Zurück
            </Button>
          )}
        </div>
      )}
    </>,
  );
}

export default Onboarding;
