import React from 'react';
// import {AdjustmentsIcon, ArrowLeftIcon, InformationCircleIcon, XCircleIcon} from "@heroicons/react/outline";
import {
  ArrowLeftIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/outline';

export default function HeaderContainer(props) {
  return (
    <div className="h-14 p-4 border-b">
      <div className="flex justify-around">
        <button>
          {/*{props.showMenu ?*/}
          {/*  (props.legal === "" ?*/}
          {/*    <AdjustmentsIcon className="h-6 w-6 w-full" onClick={() => console.log("Filter clicked")}/> :*/}
          {/*      <ArrowLeftIcon className="h-6 w-6 w-full" onClick={() => props.setLegal("")}/>*/}
          {/*  ) :*/}
          {/*  <ArrowLeftIcon className="h-6 w-6 w-full" onClick={() => props.setShowMenu(true)}/>*/}
          {/*}*/}
          {props.showMenu ? (
            props.legal !== '' && (
              <ArrowLeftIcon
                className="h-6 w-6 w-full"
                onClick={() => props.setLegal('')}
              />
            )
          ) : (
            <ArrowLeftIcon
              className="h-6 w-6 w-full"
              onClick={() => props.setShowMenu(true)}
            />
          )}
        </button>
        <div className={'text-center text-xl tracking-wider font-semibold'}>
          {props.restaurant?.name}
        </div>
        <button onClick={() => props.setShowInfo(!props.showInfo)}>
          {!props.showInfo ? (
            <InformationCircleIcon className="h-6 w-6 w-full" />
          ) : (
            <XCircleIcon className="h-6 w-6 w-full" />
          )}
        </button>
      </div>
    </div>
  );
}
