import React, { useState, useEffect } from 'react';
import { Popover } from '@headlessui/react';
import axiosApiInstance from '../Service/API';
import { CreditCardIcon, TruckIcon, HomeIcon } from '@heroicons/react/outline';
import Translation from '../Service/Translation';
import moment from 'moment';

export default function RestaurantLandingPage({ history }) {
  const [showCard, setShowCard] = useState(false);
  const [menuCardLink, setMenuCardLink] = useState('/speisekarte/');
  const [paymentMethods, setPaymentMethods] = useState('');
  const [deliveryMethods, setDeliveryMethods] = useState('');
  const [restaurantData, setRestaurantData] = useState({
    nameFirstLine: '',
    nameSecondLine: '',
    description: '',
    addressElem: <></>,
    meals: 0,
    paymentMethods: [],
    deliveryMethods: [],
    openingHours: [],
  });

  useEffect(() => {
    let urlArr = window.location.pathname.split('/');
    let restaurantSlug = urlArr[urlArr.length - 1];
    axiosApiInstance
      .get(`/restaurants/slug/${restaurantSlug}`)
      .then((response) => {
        if (response.status === 200) {
          let restaurantNameSplitted = response.data.name.split(' ');
          setRestaurantData({
            nameFirstLine: restaurantNameSplitted[0],
            nameSecondLine: restaurantNameSplitted.slice(
              Math.max(
                restaurantNameSplitted.length - restaurantNameSplitted.length,
                1,
              ),
            ),
            description: response.data.description,
            addressElem: (
              <>
                <p className={'mb-0'}>{response.data.name}</p>
                <p className={'mb-0'}>{response.data.address.street}</p>
                <p className={'mb-0'}>
                  {response.data.address.postalCode}{' '}
                  {response.data.address.city}
                </p>
              </>
            ),
            openingHours: response.data.openingHours,
          });
          let tmpPaymentMethods = [];
          let tmpDeliverMethods = [];
          response.data.paymentMethods.includes('cash') &&
            tmpPaymentMethods.push('Bar');
          response.data.paymentMethods.includes('paypal') &&
            tmpPaymentMethods.push('PayPal');
          response.data.paymentMethods.includes('stripe') &&
            tmpPaymentMethods.push(
              'Kreditkarte',
              'Klarna',
              'Apple Pay',
              'Google Pay',
            );
          response.data.deliveryMethods.includes('pickup') &&
            tmpDeliverMethods.push('Abholen');
          response.data.deliveryMethods.includes('delivery') &&
            tmpDeliverMethods.push('Liefern');
          setPaymentMethods(tmpPaymentMethods.join(', '));
          setDeliveryMethods(tmpDeliverMethods.join(', '));
          setMenuCardLink(menuCardLink + response.data.menuCards[0].uuid);
        }
      })
      .catch(() => {
        history.push('/restaurant_not_found');
        window.location.reload();
      });
  }, []);

  const loadMenu = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent,
      )
    ) {
      window.location.replace(menuCardLink);
    } else {
      setShowCard(true);
    }
  };

  return (
    <>
      {showCard && (
        <iframe
          title="menu"
          src={menuCardLink}
          style={{
            zIndex: '9998',
            position: 'fixed',
            width: '100%',
            height: '100%',
            border: 0,
          }}
        />
      )}
      {showCard && (
        <div
          className={'absolute top-0 right-0 p-4 invisible md:visible'}
          style={{ zIndex: '9999' }}
          onClick={() => setShowCard(false)}
        >
          <button
            className={
              'w-full flex items-center justify-center px-8 border border-transparent text-base font-medium rounded-md text-white bg-schmeckn-green hover:text-gray-200 md:py-2 md:text-lg md:px-10'
            }
          >
            Schließen
          </button>
        </div>
      )}
      <div
        className={`${showCard && 'h-screen overflow-hidden'} font-quicksand`}
      >
        <div className={`relative bg-gray-50 overflow-hidden border-b`}>
          <div className="max-w-7xl mx-auto">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 bg-gray-50">
              <svg
                className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-gray-50 transform translate-x-1/2"
                fill="currentColor"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="50,0 100,0 50,100 0,100" />
              </svg>

              <Popover>
                <div className="relative pt-6 px-4 sm:px-6 lg:px-8"></div>
              </Popover>

              <main className="mt-2 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span>{restaurantData.nameFirstLine}</span> <br />
                    <span className="text-schmeckn-green">
                      {restaurantData.nameSecondLine}
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    {restaurantData.description}
                  </p>
                  <div
                    className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
                    onClick={loadMenu}
                  >
                    <div className="rounded-md shadow">
                      <a
                        href="#"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-schmeckn-green hover:text-gray-200 md:py-4 md:text-lg md:px-10"
                      >
                        Speisekarte auf schmeckn anzeigen
                      </a>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              alt={'restaurant_lp_header'}
              src={'/images/restaurant_lp_header.jpg'}
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            />
          </div>
        </div>
        <section className="relative block bg-gray-50 border-b">
          <div className="container mx-auto px-4 lg:py-12 py-8">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">ÖFFNUNGSZEITEN</h2>
              </div>
            </div>
            <div className="flex flex-wrap mt-4 lg:mt-6 justify-center">
              <div className="w-full px-4 text-center">
                <div className="mt-2 mb-4">
                  <table className="w-full mx-auto max-w-xs">
                    <tbody>
                      {restaurantData.openingHours.map((data) => (
                        <tr key={data.day}>
                          <th className={'text-left'}>
                            {Translation.dayName(data.day)}
                          </th>
                          <td
                            className={
                              'text-right font-bold text-schmeckn-green'
                            }
                          >
                            {data.open === '0000' && data.close === '0000'
                              ? 'Geschlossen'
                              : `${moment(data.open, 'HHmm').format(
                                  'HH:mm',
                                )}Uhr - 
                            ${moment(data.close, 'HHmm').format('HH:mm')}Uhr`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block bg-gray-50 border-b">
          <div className="container mx-auto px-4 lg:py-12 py-8">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">ÜBER UNS</h2>
              </div>
            </div>
            <div className="flex flex-wrap mt-4 lg:mt-6 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-300 p-3 w-16 h-16 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <HomeIcon className={'h-8 w-8 icon-text-schmeckn-green'} />
                </div>
                <h6 className="text-xl mt-5 font-semibold">Adresse</h6>
                <div className="mt-2 mb-4">{restaurantData.addressElem}</div>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-300 p-3 w-16 h-16 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <CreditCardIcon
                    className={'h-8 w-8 icon-text-schmeckn-green'}
                  />
                </div>
                <h5 className="text-xl mt-5 font-semibold">Zahlungsmethoden</h5>
                <div className="mt-2 mb-4 md:px-32 lg:px-12 xl:px-20">
                  {paymentMethods}
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-300 p-3 w-16 h-16 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <TruckIcon className={'h-8 w-8 icon-text-schmeckn-green'} />
                </div>
                <h5 className="text-xl mt-5 font-semibold">Liefermethoden</h5>
                <div className="mt-2 mb-4">{deliveryMethods}</div>
              </div>
            </div>
          </div>
        </section>
        <footer className="border-gray-200 bg-gray-50">
          <div className="mx-auto flex w-full max-w-7xl flex-col-reverse items-center justify-between space-y-6 space-y-reverse px-4 py-10 text-sm text-gray-500 md:flex-row md:items-start md:space-y-0">
            <div className="flex items-center space-x-4">
              <p>schmeckn © 2022</p>
            </div>
            <nav className="flex flex-col flex-wrap items-center space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
              <button
                className={'text-gray-800'}
                onClick={() => window.klaro.show()}
              >
                Cookie Einstellungen
              </button>
              <a href="#" className={'text-gray-800'}>
                Impressum
              </a>
            </nav>
          </div>
        </footer>
      </div>
    </>
  );
}
