import React, { useEffect, useState } from 'react';
import InputSelect from '../../Generic/InputSelect';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import axiosApiInstance from '../../../Service/API';
import PaymentPayPal from './PaymentPayPal';
import PaymentStripe from './PaymentStripe';

const paymentOptions = [
  {
    id: 1,
    name: 'Bar',
    value: 'cash',
    logo: '/logos/cash.svg',
  },
  {
    id: 2,
    name: 'PayPal',
    value: 'paypal',
    logo: '/logos/paypal.svg',
  },
  {
    id: 3,
    name: 'Klarna, Apple Pay, Google Pay, Kreditkarte',
    value: 'stripe',
    logo: '/logos/stripe.svg',
  },
];

const deliveryOptions = [
  {
    id: 1,
    name: 'Abholen',
    value: 'pickup',
    logo: '/logos/pickup.svg',
  },
  {
    id: 2,
    name: 'Liefern',
    value: 'delivery',
    logo: '/logos/deliver.svg',
  },
];

function PaymentContainer(props) {
  const [stripeClientSecret, setStripeClientSecret] = useState('');
  const [paidCash, setPaidCash] = useState(false);
  const [paypalId, setPaypalId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState({
    id: 0,
    name: '',
    value: '',
    logo: '',
  });
  const [deliveryMethod, setDeliveryMethod] = useState({
    id: 0,
    name: '',
    value: '',
    logo: '',
  });
  const [restaurantPaymentMethods, setRestaurantPaymentMethods] =
    useState(paymentOptions);
  const [restaurantDeliveryMethods, setRestaurantDeliveryMethods] =
    useState(deliveryOptions);

  useEffect(() => {
    let tmpRestaurantPaymentMethods = [...restaurantPaymentMethods];
    let tmpRestaurantDeliveryMethods = [...restaurantDeliveryMethods];

    if (!props.restaurant.paymentMethods.includes('paypal')) {
      tmpRestaurantPaymentMethods = tmpRestaurantPaymentMethods.filter(
        (item) => item.value !== 'paypal',
      );
    }
    if (!props.restaurant.paymentMethods.includes('stripe')) {
      tmpRestaurantPaymentMethods = tmpRestaurantPaymentMethods.filter(
        (item) => item.value !== 'stripe',
      );
    }
    if (!props.restaurant.deliveryMethods.includes('pickup')) {
      tmpRestaurantDeliveryMethods = tmpRestaurantDeliveryMethods.filter(
        (item) => item.value !== 'pickup',
      );
    }
    if (!props.restaurant.deliveryMethods.includes('delivery')) {
      tmpRestaurantDeliveryMethods = tmpRestaurantDeliveryMethods.filter(
        (item) => item.value !== 'delivery',
      );
    }

    setRestaurantPaymentMethods(tmpRestaurantPaymentMethods);
    setRestaurantDeliveryMethods(tmpRestaurantDeliveryMethods);
    setPaymentMethod(tmpRestaurantPaymentMethods[0]);
    setDeliveryMethod(tmpRestaurantDeliveryMethods[0]);
  }, []);

  useEffect(() => {
    setStripeClientSecret('');
    setPaypalId('');

    if (paymentMethod.value !== '') {
      if (paymentMethod.value !== 'cash') {
        makeOrder(buildOrderBody(), paymentMethod.value);
      }
    }
  }, [paymentMethod, deliveryMethod]);

  const buildOrderBody = () => {
    let items = [];
    props.basket.forEach((item) => {
      let tmpExtras = [];
      item.customExtras.forEach((extra) => {
        tmpExtras.push({
          uuid: extra.uuid,
          optionIndex: extra.menuExtraType === 'radio' ? extra.optionIndex : 0,
        });
      });
      items.push({
        uuid: item.uuid,
        amount: item.pieces,
        comment: item.note,
        extras: tmpExtras,
      });
    });

    return {
      customer: {
        firstName: props.checkoutFormFields.firstName,
        lastName: props.checkoutFormFields.lastName,
        email: props.checkoutFormFields.email,
        phoneNumber: props.checkoutFormFields.phone,
      },
      restaurant: {
        uuid: props.restaurant.uuid,
      },
      paymentMethod: paymentMethod.value,
      deliveryMethod: deliveryMethod.value,
      placeId: props.checkoutFormFields.placeId,
      comment: props.checkoutFormFields.comment,
      items: items,
    };
  };

  const makeOrder = (orderBody, paymentMethod) => {
    axiosApiInstance.post('/orders', orderBody).then((response) => {
      if (response.status === 201) {
        localStorage.setItem(
          'estimatedTimeInProgress',
          response.data.estimatedTimeInProgress,
        );
        if (paymentMethod === 'paypal') {
          setPaypalId(response.data.payment.id);
        } else if (paymentMethod === 'stripe') {
          setStripeClientSecret(response.data.payment.client_secret);
        } else if (paymentMethod === 'cash') {
          props.history.push('?redirect_status=succeeded');
          window.location.reload();
        }
      }
    });
  };

  return (
    <>
      <div
        className={
          'border-b text-md font-base text-gray-600 tracking-wider mb-2 mt-6'
        }
      >
        Wie möchtest du bezahlen?
      </div>
      <div className={'space-y-2 mb-4'}>
        <InputSelect
          name={'Bestellmethode'}
          options={restaurantDeliveryMethods}
          currentOption={deliveryMethod}
          setOption={setDeliveryMethod}
        />
        <InputSelect
          name={'Bezahlmethode'}
          options={restaurantPaymentMethods}
          currentOption={paymentMethod}
          setOption={setPaymentMethod}
        />
      </div>
      <div className={'mb-2 italic text-sm leading-none'}>
        Durch Anklicken von Bestellen/Direkt zu Paypal erklärst du dich mit
        unserer Datenschutzerklärung sowie AGB einverstanden.
      </div>
      {paymentMethod.value === 'cash' && (
        <div className={'h-14 opacity-95 flex space-x-4 mt-4'}>
          <button
            className="w-full text-white bg-schmeckn-normal rounded-xl"
            disabled={paidCash}
            onClick={() => {
              makeOrder(buildOrderBody(), 'cash');
              setPaidCash(true);
            }}
          >
            <div className="flex justify-center flex-row items-center leading-6 text-white font-bold text-lg tracking-widest">
              <ShoppingCartIcon className="h-5 w-5 mr-2" />
              {paidCash ? 'Bitte warten...' : 'Kostenpflichtig bestellen'}
            </div>
          </button>
        </div>
      )}
      {stripeClientSecret && paymentMethod.value === 'stripe' && (
        <PaymentStripe
          stripeClientSecret={stripeClientSecret}
          returnUrl={window.location.href}
        />
      )}
      {paypalId && paymentMethod.value === 'paypal' && (
        <PaymentPayPal paypalId={paypalId} returnUrl={window.location.href} />
      )}
    </>
  );
}

export default PaymentContainer;
