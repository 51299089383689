import axiosApiInstance from '../../Service/API';
import { createContext, useContext, useEffect, useState } from 'react';

const IdentityContext = createContext({});

export const useIdentity = () => {
  return useContext(IdentityContext);
};

export function ProvideIdentity({ children }) {
  const [user, setUser] = useState(null);
  const [tenant, setTenant] = useState(null);

  const fetchIdentity = () => {
    axiosApiInstance.get('/users/self').then((res) => {
      // must resolve user from response
      if (res.data?.uuid) {
        setUser(res.data);
      } else {
        throw new Error('Cannot resolve user');
      }

      // can resolve tenant from response
      if (res.data?.tenants?.length >= 1 && res.data.tenants[0]?.uuid)
        setTenant(res.data.tenants[0]);
    });
  };

  useEffect(() => {
    fetchIdentity();
  }, []);

  // Make sure that the axiosApiInstance uses the new tenant
  useEffect(() => {
    if (!tenant) return;
    localStorage.setItem('tenantsUUID', tenant.uuid);
  }, [tenant]);

  return (
    <IdentityContext.Provider value={{ user, setUser, tenant, setTenant }}>
      {user ? children : <></>}
    </IdentityContext.Provider>
  );
}
