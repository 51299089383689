import React, { useState, useEffect } from 'react';
import CardContainer from './Card/CardContainer';
import { useLocation, useHistory } from 'react-router-dom';
import Information from './Header/Information';
import axiosApiInstance from '../Service/API';
import moment from 'moment';
import PaymentStatus from './PaymentStatus';
import LegalContainer from './Legal/LegalContainer';
import HeaderContainer from './Header/HeaderContainer';

function MenuContainer(props) {
  const { push } = useHistory();
  const [basket, setBasket] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [showMenu, setShowMenu] = useState(true);
  const [restaurant, setRestaurant] = useState({});
  const [menuCard, setMenuCard] = useState({});
  const [allMenuItems, setAllMenuItems] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [canAcceptOrders, setCanAcceptOrders] = useState('');
  const [legal, setLegal] = useState('');
  const search = useLocation().search;
  const [checkoutFormFields, setCheckoutFormFields] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    addressObject: '',
    formattedAddress: '',
    placeId: '',
    comment: '',
  });

  useEffect(() => {
    const redirect_status = new URLSearchParams(search).get('redirect_status');
    if (redirect_status) {
      setPaymentStatus(redirect_status);
    } else {
      push(window.location.pathname);
    }

    if (!paymentStatus) {
      const menuCardUuid = window.location.pathname.split('/')[2];
      if (!menuCardUuid) return;
      axiosApiInstance.get(`/menu-cards/${menuCardUuid}`).then((response) => {
        if (!response.data.restaurants[0].canAcceptOrders) {
          setCanAcceptOrders('disabled');
        }

        let today = moment();
        let currentDay = today.locale('en').format('dddd').toLowerCase();
        let currentTime = today.format('HHmm');

        response.data.restaurants[0].openingHours.forEach((data) => {
          if (data.day === currentDay) {
            if (data.open === '0000' && data.close === '0000') {
              setCanAcceptOrders('closed');
            }
            if (
              moment(currentTime, 'HHmm').isBefore(moment(data.open, 'HHmm')) ||
              moment(currentTime, 'HHmm').isAfter(moment(data.close, 'HHmm'))
            ) {
              setCanAcceptOrders('closed');
            }
          }
        });

        setMenuCard(response.data);
        let tmpAllMenuItems = [];
        response.data.categories.forEach((category) => {
          category.items.forEach((item) => {
            tmpAllMenuItems.push(item);
          });
        });
        setRestaurant(response.data.restaurants[0]);
        setAllMenuItems(tmpAllMenuItems);
        setMenuCard(response.data);
      });
    }
  }, []);

  return (
    <>
      <div
        className={
          'h-screen bg-gray-500 bg-opacity-60 z-100 md:py-5 flex text-gray-700 backdrop-filter backdrop-blur-sm'
        }
      >
        <div className="max-w-xl mx-auto shadow-md flex flex-col w-full bg-white relative md:rounded-lg font-quicksand">
          <HeaderContainer
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            legal={legal}
            setLegal={setLegal}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            restaurant={restaurant}
          />
          {showInfo && (
            <Information setShowInfo={setShowInfo} restaurant={restaurant} />
          )}
          {paymentStatus === 'succeeded' && (
            <PaymentStatus
              status={'succeeded'}
              setPaymentStatus={setPaymentStatus}
            />
          )}
          {paymentStatus === 'failed' && (
            <PaymentStatus
              status={'failed'}
              setPaymentStatus={setPaymentStatus}
            />
          )}
          {paymentStatus === '' &&
            (legal === '' ? (
              <CardContainer
                restaurant={restaurant}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                menuCard={menuCard}
                allMenuItems={allMenuItems}
                setShowInfo={setShowInfo}
                basket={basket}
                setBasket={setBasket}
                canAcceptOrders={canAcceptOrders}
                checkoutFormFields={checkoutFormFields}
                setCheckoutFormFields={setCheckoutFormFields}
                setLegal={setLegal}
                history={props.history}
              />
            ) : (
              <LegalContainer text={legal} />
            ))}
        </div>
      </div>
    </>
  );
}

export default MenuContainer;
