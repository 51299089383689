import { Card, Result, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import axiosApiInstance from '../Service/API';

function PayPalCallback() {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const authorizationCode = new URLSearchParams(window.location.search).get(
      'code',
    );
    if (!authorizationCode) {
      setError('Kein PayPal-Code in URL gefunden.');
      setLoading(false);
      return;
    }
    axiosApiInstance
      .post('/paypal/account/connect', { authorizationCode: authorizationCode })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Card title="Mit PayPal verbinden">
      <Skeleton loading={isLoading}>
        <Result
          status={!error ? 'success' : 'error'}
          title={!error ? 'Erfolgreich!' : 'Fehler!'}
          subTitle={
            !error
              ? 'Dein PayPal Account wurde erfolgreich mit schmeckn verbunden.'
              : error
          }
        />
      </Skeleton>
    </Card>
  );
}

export default PayPalCallback;
