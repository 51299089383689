import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';

export default function OrderStateButtons(props) {
  const [buttonElem, setButtonElem] = useState();

  useEffect(() => {
    let elem;
    if (props.orderStateName === 'open') {
      elem = (
        <Button
          type="primary"
          size={'small'}
          onClick={() => props.updateOrderState('in-progress', props.row.uuid)}
          shape="round"
        >
          In Bearbeitung
        </Button>
      );
    } else if (props.orderStateName === 'in-progress') {
      elem = (
        <Button
          type="primary"
          size={'small'}
          onClick={() => props.updateOrderState('ready', props.row.uuid)}
          shape="round"
        >
          Bereit
        </Button>
      );
    } else if (props.orderStateName === 'ready') {
      if (props.row.deliveryMethod === 'delivery') {
        elem = (
          <Button
            type="primary"
            size={'small'}
            onClick={() =>
              props.updateOrderState('in-delivery', props.row.uuid)
            }
            shape="round"
          >
            In Auslieferung
          </Button>
        );
      } else if (props.row.deliveryMethod === 'pickup') {
        elem = (
          <Button
            type="primary"
            size={'small'}
            onClick={() => props.updateOrderState('done', props.row.uuid)}
            shape="round"
          >
            Abgeschlossen
          </Button>
        );
      }
    } else if (props.orderStateName === 'in-delivery') {
      elem = (
        <Button
          type="primary"
          size={'small'}
          onClick={() => props.updateOrderState('done', props.row.uuid)}
          shape="round"
        >
          Abgeschlossen
        </Button>
      );
    } else if (props.orderStateName === 'canceled') {
      elem = (
        <Button
          type="primary"
          shape="circle"
          icon={<StopOutlined style={{ color: '#cf1e22' }} />}
          size={'small'}
          style={{ background: '#fef1f0', borderColor: '#cf1e22' }}
        />
      );
    } else if (props.orderStateName === 'done') {
      elem = (
        <Button
          type="primary"
          shape="circle"
          icon={<CheckOutlined style={{ color: '#3ea114' }} />}
          size={'small'}
          style={{ background: '#f6ffed', borderColor: '#3ea114' }}
        />
      );
    }

    setButtonElem(elem);
  }, [props.orderStateName]);

  return <>{buttonElem}</>;
}
