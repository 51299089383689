import { useEffect, useState } from 'react';
import * as Klaro from 'klaro/dist/klaro-no-css';
import './../../klaro.css';
import ReactGA from 'react-ga';
import Env from './Env';
import { useLocation } from 'react-router-dom';

const useCookieConsent = () => {
  const location = useLocation();
  const [initializedGoogleAnalytics, setInitializedGoogleAnalytics] =
    useState(false);

  const initGoogleAnalytics = (accepted) => {
    if (accepted) {
      if (!initializedGoogleAnalytics) {
        if (!window.location.href.includes('localhost')) {
          ReactGA.initialize(Env.GOOGLE_ANALYTICS_TRACKING_CODE);
          setInitializedGoogleAnalytics(true);
        } else {
          ReactGA.initialize('UA-223824182-2');
          setInitializedGoogleAnalytics(true);
        }
      }
    } else {
      document.cookie =
        '_ga=; Max-Age=0; path=/; domain=' + window.location.hostname;
      document.cookie =
        '_gid=; Max-Age=0; path=/; domain=' + window.location.hostname;
      document.cookie =
        '_gat=; Max-Age=0; path=/; domain=' + window.location.hostname;
    }
  };

  const config = {
    translations: {
      de: {
        privacyPolicyUrl: '/datenschutz',
        purposes: {
          technical: {
            title: 'Technisch',
            description:
              'Diese Cookies sind erforderlich, um schmeckn nutzen zu können.',
          },
          analytics: {
            title: 'Besucher-Statistiken',
          },
          chat: {
            title: 'Ticket-System',
          },
        },
        googleMaps: {
          title: 'Google Maps',
          description:
            'Dieses Cookie ist notwendig, damit wir deine Adresse ' +
            'korrekt an das Restaurant übermitteln können.',
        },
        sentry: {
          title: 'Sentry',
          description:
            'Erzeugt im Falle eines Fehlers einen Absturzbericht. ' +
            'Anhand dessen können wir Fehler in unserer Webseite entdecken ' +
            'und beheben.',
        },
        chatwoot: {
          title: 'Chatwoot',
          description:
            'Mithilfe dieses Cookies können Restaurantbetreiber ' +
            'unseren Support kontaktieren.',
        },
        googleAnalytics: {
          title: 'Google Analytics',
          description:
            'Mit diesem Cookie ist es uns möglich, die ' +
            'Benutzerfreundlichkeit zu verbessern.',
        },
      },
    },

    services: [
      {
        name: 'googleMaps',
        default: true,
        required: true,
        purposes: ['technical'],
      },
      {
        name: 'sentry',
        default: true,
        required: true,
        purposes: ['technical'],
      },
      {
        name: 'chatwoot',
        default: true,
        required: true,
        purposes: ['technical'],
      },
      {
        name: 'googleAnalytics',
        purposes: ['analytics'],
        callback: function (accepted) {
          initGoogleAnalytics(accepted);
        },
      },
    ],
    acceptAll: true,
    mustConsent: true,
    hideDeclineAll: true,
    lang: 'de',
  };

  window.klaro = Klaro;
  window.klaroConfig = config;
  Klaro.setup(config);

  // Used to track each pageview in google analytics
  useEffect(() => {
    if (initializedGoogleAnalytics) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initializedGoogleAnalytics, location]);
};

export default useCookieConsent;
