import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axiosApiInstance from '../Service/API';

export default function EmailVerification(props) {
  const [verificationCompleted, setVerificationCompleted] = useState('loading');

  const location = useLocation();
  let verificationEndpoint = '';

  if (location.pathname === '/acceptDriverInvite') {
    verificationEndpoint = '/drivers/accept-driver-invite';
  } else if (location.pathname === '/emailverification') {
    verificationEndpoint = '/users/emailVerification';
  }

  useEffect(() => {
    const verificationToken = new URLSearchParams(location.search).get('token');
    if (verificationToken) {
      try {
        axiosApiInstance
          .put(`${verificationEndpoint}/${verificationToken}`)
          .then((response) => {
            if (response.status === 200) {
              setVerificationCompleted('success');
              setTimeout(() => {
                props.history.push('/login');
              }, 2500);
            } else {
              setVerificationCompleted('failed');
            }
          });
      } catch (err) {
        setVerificationCompleted('failed');
      }
    } else {
      setVerificationCompleted('failed');
    }
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={'bg-schmeckn-login'}
    >
      <div
        style={{
          padding: '2em 5em',
          backdropFilter: 'blur(14px) saturate(200%)',
          backgroundColor: 'rgba(255,255,255,0.6)',
          borderRadius: '12px',
          border: '1px solid rgba(255, 255, 255, 0.125)',
        }}
      >
        <div
          style={{
            fontSize: '26px',
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1em',
          }}
        >
          {verificationCompleted === 'loading' && 'Einen Moment bitte...'}
          {verificationCompleted === 'success' && 'Account Aktiviert'}
          {verificationCompleted === 'failed' && 'Aktivierung fehlgeschlagen'}
        </div>
        <p>
          {(verificationCompleted === 'success' || 'loading') &&
            'Du wirst in ein paar Sekunden automatisch weitergeleitet'}
          {verificationCompleted === 'failed' &&
            'Bitte kontaktiere uns unter support@schmeckn.de'}
        </p>
      </div>
    </div>
  );
}
