import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ItemExtraCheckbox from './ItemExtraCheckbox';
import ItemExtraRadio from './ItemExtraRadio';
import InputTextarea from '../../Generic/InputTextarea';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { ConvertPriceAmount } from '../../../Service/Helper';

function ItemModal(props) {
  const emptyBaseItem = {
    uuid: '',
    name: '',
    extras: [],
    basePrice: 0,
    extrasBasePrice: 0,
    finalPrice: 0,
    pieces: 1,
    note: '',
  };
  const [fullItem, setFullItem] = useState(emptyBaseItem);
  const [itemExtras, setItemExtras] = useState([]);
  const [showAllCheckboxExtras, setShowAllCheckboxExtras] = useState(false);
  const [countCheckboxExtras, setCountCheckboxExtras] = useState(0);
  const [countRadioExtras, setCountRadioExtras] = useState(0);

  useEffect(() => {
    let newFullItem;
    if (props.operation === 'add') {
      let realPrice = props.item.price;
      props.item.extras.forEach((extra) => {
        if (extra.menuExtraType === 'radio') {
          extra.options.forEach((option) => {
            if (option.isDefault) {
              realPrice += option.priceDiff;
            }
          });
        }
      });
      newFullItem = {
        uuid: props.item.uuid,
        name: props.item.name,
        extras: props.item.extras,
        basePrice: props.item.price,
        extrasBasePrice: realPrice,
        finalPrice: realPrice,
        pieces: 1,
        note: '',
      };
    } else if (props.operation === 'edit') {
      newFullItem = props.item;
      setItemExtras([...props.item.customExtras]);
    }

    // Build init base item
    setFullItem(newFullItem);

    if (props.isOpen) {
      let countCheckboxExtras = 0;
      let countRadioExtras = 0;
      newFullItem.extras.forEach((extra) => {
        if (extra.menuExtraType === 'checkbox') {
          countCheckboxExtras += 1;
        } else if (extra.menuExtraType === 'radio') {
          countRadioExtras += 1;
        }
      });
      setCountCheckboxExtras(countCheckboxExtras);
      setCountRadioExtras(countRadioExtras);
    }
  }, [props.isOpen]);

  useEffect(() => {
    let newFinalPrice = fullItem.pieces * fullItem.basePrice;
    itemExtras.forEach((extra) => {
      newFinalPrice += extra.priceDiff * fullItem.pieces;
    });
    setFullItem({ ...fullItem, finalPrice: newFinalPrice });
  }, [fullItem.pieces, itemExtras]);

  const changePieces = (operator) => {
    if (operator === '+') {
      if (fullItem.pieces < 99) {
        setFullItem({
          ...fullItem,
          pieces: fullItem.pieces + 1,
        });
      }
    } else {
      if (fullItem.pieces > 1) {
        setFullItem({
          ...fullItem,
          pieces: fullItem.pieces - 1,
        });
      }
    }
  };

  const resetModal = () => {
    setItemExtras([]);
    setFullItem(emptyBaseItem);
    setShowAllCheckboxExtras(false);
  };

  const buildBasketItem = () => {
    let newBasketItem = { ...fullItem, customExtras: itemExtras };
    if (props.operation === 'add') {
      props.addToBasket(newBasketItem);
    } else if (props.operation === 'edit') {
      newBasketItem['id'] = props.item.id;
      props.changeBasket(newBasketItem, 'edit');
    }
    resetModal();
    props.toggleModal();
  };

  const closeModal = () => {
    resetModal();
    props.toggleModal();
  };

  const showCheckboxExtras = () => {
    let radioExtrasToShow = [];
    let radioExtrasToHide = [];
    fullItem.extras.forEach((extra, index) => {
      if (extra.menuExtraType === 'checkbox') {
        if (index >= 0 && index <= 2) {
          radioExtrasToShow.push(
            <ItemExtraCheckbox
              key={extra.uuid}
              itemExtra={extra}
              itemExtras={itemExtras}
              setItemExtras={setItemExtras}
              fullItem={fullItem}
              setFullItem={setFullItem}
              operation={props.operation}
            />,
          );
        } else if (index >= 2) {
          radioExtrasToHide.push(
            <ItemExtraCheckbox
              key={extra.uuid}
              itemExtra={extra}
              itemExtras={itemExtras}
              setItemExtras={setItemExtras}
              fullItem={fullItem}
              setFullItem={setFullItem}
              operation={props.operation}
            />,
          );
        }
      }
    });

    return (
      <div className={'flex flex-wrap'}>
        {radioExtrasToShow}
        {showAllCheckboxExtras && radioExtrasToHide}
        {countCheckboxExtras >= 3 && (
          <div
            className={'cursor-pointer pl-1'}
            onClick={() => setShowAllCheckboxExtras(!showAllCheckboxExtras)}
          >
            <div className={'flex items-center justify-center'}>
              {showAllCheckboxExtras ? (
                <div className={'font-bold text-schmeckn-normal'}>
                  ᐱ Weniger Extras anzeigen
                </div>
              ) : (
                <div className={'font-bold text-schmeckn-normal'}>
                  ᐯ Alle Extras anzeigen
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-100 bg-opacity-10 backdrop-filter backdrop-blur-sm" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            ></span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden align-middle transition-all transform bg-white shadow rounded-xl border font-quicksand">
                <div className={'absolute top-0 right-0 p-2'}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    onClick={closeModal}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <Dialog.Title
                  as="div"
                  className="leading-6 text-gray-900 text-xl font-medium text-center tracking-widest font-semibold"
                >
                  {fullItem.name}
                </Dialog.Title>
                <div className={'mb-4'}>{props.item.description}</div>
                {countRadioExtras !== 0 && (
                  <div className="mb-6">
                    {fullItem.extras.map(
                      (extra) =>
                        extra.menuExtraType === 'radio' && (
                          <div key={extra.uuid}>
                            <div className={'text-base font-semibold mb-2'}>
                              {extra.name}
                            </div>
                            <ItemExtraRadio
                              key={extra.uuid}
                              itemExtra={extra}
                              itemExtras={itemExtras}
                              setItemExtras={setItemExtras}
                              fullItem={fullItem}
                              setFullItem={setFullItem}
                              operation={props.operation}
                            />
                          </div>
                        ),
                    )}
                  </div>
                )}
                {countCheckboxExtras !== 0 && (
                  <div className="mb-6 flex flex-col justify-center">
                    <div className={'text-base font-semibold mb-2'}>Extras</div>
                    {props.isOpen && showCheckboxExtras()}
                  </div>
                )}
                <div>
                  <div className="flex flex-col">
                    <label className="text-base font-semibold mb-1">
                      Notiz
                    </label>
                    <InputTextarea
                      value={fullItem.note}
                      onChange={(e) =>
                        setFullItem({ ...fullItem, note: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="mt-4 flex justify-between">
                  <div className="flex w-24 justify-evenly border rounded">
                    <button
                      onClick={() => changePieces('-')}
                      style={{ width: '100%' }}
                    >
                      <div className="flex h-full w-full justify-center items-center border-r">
                        <MinusIcon className="h-4 w-4" />
                      </div>
                    </button>
                    <div className="flex h-full w-full justify-center items-center text-schmeckn-normal font-bold select-none">
                      {fullItem.pieces}
                    </div>
                    <button
                      onClick={() => changePieces('+')}
                      style={{ width: '100%' }}
                    >
                      <div className="flex h-full w-full justify-center items-center border-l">
                        <PlusIcon className="h-4 w-4" />
                      </div>
                    </button>
                  </div>
                  <button
                    disabled={props.canAcceptOrders !== ''}
                    type="button"
                    className={`${
                      props.canAcceptOrders !== ''
                        ? 'bg-gray-400'
                        : 'bg-schmeckn-normal'
                    } inline-flex justify-center px-4 py-2 text-sm font-bold text-white border border-transparent rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500`}
                    onClick={buildBasketItem}
                  >
                    {`${
                      props.operation === 'add' ? 'Hinzufügen' : 'Ändern'
                    } ${ConvertPriceAmount(fullItem.finalPrice)}€`}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ItemModal;
