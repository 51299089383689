import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Button,
  Popconfirm,
  Tag,
  Checkbox,
  message,
  Select,
  Form,
  Input,
} from 'antd';
import axiosApiInstance from '../../Service/API';
import Translation, {
  orderStateNameTranslations,
} from '../../Service/Translation';
import moment from 'moment';
import OrderDetails from './OrderDetails';
import {
  InfoCircleOutlined,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import OrderStateButtons from './OrderStateButtons';
import { ConvertPriceAmount } from '../../Service/Helper';
import BackendModal from '../Utils/BackendModal';
import Env from '../../Service/Env';
import { useIdentity } from '../Utils/useIdentity';

const { Option } = Select;

export default function Orders(props) {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [refundCustomerOnCancel, setRefundCustomerOnCancel] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [choosenDriver, setChoosenDriver] = useState();
  const [form] = Form.useForm();
  const { tenant } = useIdentity();

  useEffect(() => {
    setLoadingTable(true);
    props.getOrders();
    axiosApiInstance.get('/drivers/self').then((response) => {
      let elems = [];
      response.data.forEach((driver) => {
        elems.push(
          <Option
            key={driver.uuid}
          >{`${driver.name} - ${driver.email}`}</Option>,
        );
      });
      setDrivers(elems);
    });
    setLoadingTable(false);
  }, [tenant]);

  const rowSelection = {
    selectedRows,
    onChange: (a, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        record.orderStateName !== 'ready' ||
        record.deliveryMethod !== 'delivery', // Column configuration not to be checked
    }),
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const updateOrderState = (newOrderState, orderUUID, data = {}) => {
    setLoadingTable(true);
    axiosApiInstance
      .put(`orders/${orderUUID}/order-state/to-${newOrderState}`, data)
      .then((response) => {
        if (response.status === 200) {
          message.success('Status erfolgreich geändert');
        } else {
          message.error('Fehler beim ändern des Status');
        }
      })
      .finally(() => {
        setLoadingTable(false);
        setRefundCustomerOnCancel(false);
      });
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'orderStateName',
      width: '10%',
      render: (orderStateName) => (
        <>
          {orderStateName !== 'done' && orderStateName !== 'canceled' && (
            <Tag color="orange">
              {orderStateNameTranslations[orderStateName]}
            </Tag>
          )}
          {orderStateName === 'done' && (
            <Tag color="green">
              {orderStateNameTranslations[orderStateName]}
            </Tag>
          )}
          {orderStateName === 'canceled' && (
            <Tag color="red">{orderStateNameTranslations[orderStateName]}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Status wechseln',
      dataIndex: 'orderStateName',
      width: '10%',
      render: (orderStateName, row) => (
        <>
          <OrderStateButtons
            orderStateName={orderStateName}
            row={row}
            updateOrderState={updateOrderState}
          />
        </>
      ),
    },
    {
      title: 'Bestellnummer',
      dataIndex: 'orderNumber',
      sorter: (a, b) => a.orderNumber - b.orderNumber,
      defaultSortOrder: 'descending',
      render: (orderNumber) => <span>#{orderNumber}</span>,
    },
    {
      title: 'Bezahlmethode',
      dataIndex: 'paymentMethod',
      responsive: ['xxl'],
      render: (paymentMethod) => Translation.paymentMethod(paymentMethod),
    },
    {
      title: 'Liefermethode',
      dataIndex: 'deliveryMethod',
      responsive: ['xxl'],
      render: (deliveryMethod) => Translation.deliveryMethod(deliveryMethod),
    },
    {
      title: 'Summe',
      dataIndex: 'fullPrice',
      render: (fullPrice) => `${ConvertPriceAmount(fullPrice)}€`,
    },
    {
      title: 'Zeitstempel',
      dataIndex: 'createdAt',
      responsive: ['xl'],
      render: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      title: 'Abbrechen',
      render: (row) => (
        <Popconfirm
          onCancel={() => setRefundCustomerOnCancel(false)}
          title={
            <>
              <div>Sind Sie sich sicher?</div>
              <div>
                Dadurch wird die Bestellung <b>vollständig abgebrochen</b>.
              </div>
              <div>Ihr Kunde wird per E-Mail über alles informiert.</div>
              {row.paymentMethod === 'paypal' && (
                <>
                  <div style={{ fontStyle: 'italic' }}>
                    Aktuell nur über dein PayPal Dashboard möglich.
                  </div>
                  <div style={{ fontStyle: 'italic' }}>
                    Bitte logge dich dazu in PayPal ein.
                  </div>
                </>
              )}
              <Checkbox
                onChange={() =>
                  setRefundCustomerOnCancel(!refundCustomerOnCancel)
                }
                checked={refundCustomerOnCancel}
                disabled={row.paymentMethod !== 'stripe'}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '1em 0',
                }}
              >
                <b>Geld zurück erstatten</b>
              </Checkbox>
            </>
          }
          onConfirm={() =>
            updateOrderState('canceled', row.uuid, {
              refund: refundCustomerOnCancel,
            })
          }
          okText="Bestellung abbrechen"
          cancelText="Fenster schließen"
          disabled={row.orderStateName !== 'open'}
        >
          <Button
            type="primary"
            danger
            icon={<StopOutlined />}
            disabled={row.orderStateName !== 'open'}
            size={'small'}
          >
            Abbrechen
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Card
        title="Bestellungen zuweisen"
        style={{ height: '100%', overflow: 'auto' }}
      >
        {Env.FEATURE_DRIVER_MANAGEMENT && (
          <Button
            size="small"
            style={{ marginBottom: '15px' }}
            type="primary"
            icon={<PlusOutlined />}
            disabled={selectedRows.length === 0}
            onClick={() => {
              toggleModal();
            }}
          >
            Ausgewählte Bestellungen an Fahrer senden
          </Button>
        )}
        <Table
          loading={loadingTable}
          dataSource={props.orders}
          columns={columns}
          rowKey="uuid"
          rowSelection={
            Env.FEATURE_DRIVER_MANAGEMENT && {
              type: 'checkbox',
              ...rowSelection,
            }
          }
          expandable={{
            expandRowByClick: false,
            expandedRowRender: (record) => <OrderDetails order={record} />,
            expandedRowKeys: expandedRowKeys,
            rowExpandable: (_) => true,
            onExpandedRowsChange: (rowKeys) => {
              setExpandedRowKeys(rowKeys);
            },
          }}
        />
      </Card>
      <BackendModal
        title={'Fahrer zuweisen'}
        toggleModal={toggleModal}
        modalVisible={modalVisible}
        okText={`Bestellungen an Fahrer senden`}
        onOk={() => {
          form.validateFields().then((values) => {
            delete values['driver'];
            let orders = [];
            selectedRows.forEach((order) => {
              orders.push({ uuid: order.uuid });
            });
            values['orders'] = orders;
            axiosApiInstance
              .put(`/drivers/${choosenDriver}/assign-orders`, values)
              .then((response) => {
                if (response.status === 200) {
                  toggleModal();
                  message.success('Bestellung erfolgreich an Fahrer gesendet');
                  setSelectedRows([]);
                  form.resetFields();
                } else {
                  message.error('Fehler beim senden der Bestellung an Fahrer');
                }
              });
          });
        }}
        content={
          <>
            <Form form={form} layout="vertical">
              <Form.Item
                label="Fahrer"
                name="driver"
                hasFeedback
                rules={[
                  { required: true, message: 'Bitte einen Fahrer auswählen' },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Bitte auswählen"
                  onChange={(e) => setChoosenDriver(e)}
                >
                  {drivers}
                </Select>
              </Form.Item>
              <Form.Item
                label="Nachricht"
                name="internalMessage"
                hasFeedback
                tooltip={{
                  title: <div>Eine optionale Nachricht für den Fahrer</div>,
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input.TextArea />
              </Form.Item>
            </Form>
          </>
        }
      />
    </>
  );
}
