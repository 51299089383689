import React from 'react';
import { Row, TimePicker } from 'antd';
import moment from 'moment';
import Translation from '../../Service/Translation';

export default function RestaurantOpeningHours(props) {
  const changeOpeningHours = (openOrClose, currentDay, time) => {
    let tmpOpeningHours = [...props.openingHours];
    if (!time) time = '';
    tmpOpeningHours.forEach((data) => {
      if (data.day === currentDay) {
        data[openOrClose] = time !== '' ? time.format('HHmm') : '';
      }
    });
    props.setOpeningHours(tmpOpeningHours);
  };

  return (
    <>
      <Row
        style={{
          marginBottom: '1em',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <table>
          <tbody>
            {props.openingHours.map((data) => (
              <tr key={data.day}>
                <td>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      marginRight: '1em',
                    }}
                  >
                    {Translation.dayName(data.day)}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      marginRight: '0.5em',
                    }}
                  >
                    von
                  </span>
                </td>
                <td>
                  <TimePicker
                    onChange={(value) =>
                      changeOpeningHours('open', data.day, value)
                    }
                    style={{ marginRight: '0.5em', width: '12em' }}
                    // value={moment(data.open, "HHmm").format("HH:mm")}
                    value={moment(data.open, 'HH:mm')}
                    format={'HH:mm'}
                    minuteStep={15}
                  />
                </td>
                <td>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      marginRight: '0.5em',
                    }}
                  >
                    bis
                  </span>
                </td>
                <td>
                  <TimePicker
                    onChange={(value) =>
                      changeOpeningHours('close', data.day, value)
                    }
                    style={{ width: '12em' }}
                    value={moment(data.close, 'HH:mm')}
                    format={'HH:mm'}
                    minuteStep={15}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Row>
    </>
  );
}
