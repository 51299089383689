import React from 'react';
import CartItem from './CartItem';
import { ConvertPriceAmount } from '../../../Service/Helper';

function CartContainer(props) {
  const getCheckoutPrice = () => {
    let fullPrice = 0;
    for (let i = 0; i < props.basket.length; i++) {
      fullPrice = fullPrice + props.basket[i].finalPrice;
    }
    return ConvertPriceAmount(fullPrice);
  };

  return (
    <>
      <div
        className={
          'border-b text-md font-base text-gray-600 tracking-wider mb-2 mt-6'
        }
      >
        Alles richtig?
      </div>
      <div className="flex flex-col mb-2">
        {props.basket.map((item, index) => (
          <CartItem
            key={index}
            basketItem={item}
            originalItems={props.originalItems}
            changeBasket={props.changeBasket}
            setModalState={props.setModalState}
          />
        ))}
      </div>
      {/*<div className={"flex justify-between align-center border-t text-base font-medium line-through"}>*/}
      <div
        className={
          'flex justify-between align-center border-t text-base font-semibold'
        }
      >
        {/*<span className={"text-gray-700"}>*/}
        {/*  Lieferkosten:*/}
        {/*</span>*/}
        {/*<span className={""}>*/}
        {/*  10.00€*/}
        {/*</span>*/}
      </div>
      <div className={'flex justify-between align-center text-base font-bold'}>
        <span className={'text-gray-700'}>Gesamt:</span>
        <span>{getCheckoutPrice().replace('.', ',')}€</span>
      </div>
    </>
  );
}

export default CartContainer;
