import React, { useEffect, useState } from 'react';
import CategoryNavbar from './Category/CategoryNavbar';
import CategoryContainer from './Category/CategoryContainer';
import CheckoutContainer from '../Checkout/CheckoutContainer';
import { ConvertPriceAmount } from '../../Service/Helper';

export default function CardContainer(props) {
  const [showModal, setShowModal] = useState(false);

  const addToBasket = (item) => {
    let itemId = 0;
    for (let i = 0; i < props.basket.length; i++) {
      itemId++;
    }
    props.setBasket([
      ...props.basket,
      {
        id: itemId,
        ...item,
      },
    ]);
  };

  useEffect(() => {
    let path = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
    );
    if (path[0] === '#') {
      setTimeout(() => {
        document
          .getElementById(path.replace('#', ''))
          .scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (props.basket.length === 0) {
      props.setShowMenu(true);
    }
  }, [props.basket]);

  const editBasket = (item, operation) => {
    let currentBasket = [...props.basket];

    for (let i = 0; i < currentBasket.length; i++) {
      if (currentBasket[i].id === item.id) {
        if (operation === 'edit') {
          currentBasket[i] = item;
        } else if (operation === 'delete') {
          currentBasket = currentBasket.filter(
            (basketItem) => basketItem.id !== item.id,
          );
        }
      }
    }
    props.setBasket(currentBasket);
  };

  const getCheckoutPrice = () => {
    let fullPrice = 0;
    props.basket.forEach((item) => {
      fullPrice += item.finalPrice;
    });
    return ConvertPriceAmount(fullPrice);
  };

  return (
    <>
      {props.showMenu ? (
        <>
          <CategoryNavbar categories={props.menuCard?.categories} />
          {props.canAcceptOrders !== '' && (
            <div
              className={`py-1 px-4 ${
                props.canAcceptOrders === 'disabled' && 'bg-yellow-300'
              } ${props.canAcceptOrders === 'closed' && 'bg-red-300'}`}
            >
              <div className="flex justify-center mb-1 text-gray-600">
                <div className={'font-bold text-base text-center text-black'}>
                  {props.canAcceptOrders === 'disabled' &&
                    'Das Restaurant hat geöffnet, nimmt aber derzeit keine Bestellungen über schmeckn auf.'}
                  {props.canAcceptOrders === 'closed' &&
                    'Das Restaurant hat derzeit geschlossen, komm gerne später nochmal hier vorbei.'}
                </div>
              </div>
            </div>
          )}
          <div className="flex-1 px-4 overflow-auto space-y-2 no-scrollbar pb-20">
            {props.menuCard?.categories?.map(
              (category, index) =>
                category.items.length > 0 && (
                  <CategoryContainer
                    key={index}
                    category={category}
                    addToBasket={addToBasket}
                    setModalState={() => setShowModal(!showModal)}
                    restaurant={props.restaurant}
                    canAcceptOrders={props.canAcceptOrders}
                  />
                ),
            )}
            <div className={'bg-white rounded-xl py-1 px-4 mb-2 border shadow'}>
              <div className={'w-full flex justify-center top-4 text-base'}>
                <button
                  className={'px-2 text-center font-medium'}
                  onClick={() => props.setLegal('tos')}
                >
                  AGB
                </button>
                |
                <button
                  className={'px-2 text-center font-medium'}
                  onClick={() => props.setLegal('privacy')}
                >
                  Datenschutzerklärung
                </button>
                |
                <button
                  className={'px-2 text-center font-medium'}
                  onClick={() => window.klaro.show()}
                >
                  Cookies
                </button>
                |
                <button
                  className={'px-2 text-center font-medium'}
                  onClick={() => props.setLegal('imprint')}
                >
                  Impressum
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              'h-14 w-full px-2 place-items-center absolute bottom-4 opacity-95 ' +
              (props.basket.length === 0 && 'hidden')
            }
          >
            <button
              className="w-full h-full text-white bg-schmeckn-normal rounded-xl"
              onClick={() => {
                props.setShowMenu(false);
                props.setShowInfo(false);
              }}
              disabled={props.basket.length === 0}
            >
              <div className="text-white text-base font-medium text-center tracking-wider font-bold">
                Warenkorb {getCheckoutPrice().replace('.', ',')}€
              </div>
            </button>
          </div>
        </>
      ) : (
        <div className="flex-1 p-4 overflow-auto space-y-2">
          <CheckoutContainer
            setShowMenu={props.setShowMenu}
            basket={props.basket}
            originalItems={props.allMenuItems}
            changeBasket={editBasket}
            setModalState={() => setShowModal(!showModal)}
            restaurant={props.restaurant}
            checkoutFormFields={props.checkoutFormFields}
            setCheckoutFormFields={props.setCheckoutFormFields}
            history={props.history}
          />
        </div>
      )}
    </>
  );
}
