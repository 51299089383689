import React from 'react';
import ItemContainer from '../Item/ItemContainer';

function CategoryContainer(props) {
  return (
    <>
      <div>
        <div
          className={
            'w-full text-center text-xl text-divider my-4 tracking-wider font-bold'
          }
        >
          <span id={props.category.name.replace(' ', '_')}>
            {props.category.name}
          </span>
        </div>
        {props.category.items.map((item, index) => (
          <ItemContainer
            key={index}
            item={item}
            setModalState={props.setModalState}
            addToBasket={props.addToBasket}
            canAcceptOrders={props.canAcceptOrders}
          />
        ))}
      </div>
    </>
  );
}

export default CategoryContainer;
