import React from 'react';
import { Card, Typography } from 'antd';

const { Title } = Typography;

function Overview() {
  return (
    <>
      <Card title="Übersicht" style={{ height: '100%', overflow: 'auto' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={1}>Hier entsteht aktuell die Übersicht</Title>
        </div>
      </Card>
    </>
  );
}

export default Overview;
