import React from 'react';

export default function InputTextarea(props) {
  return (
    <div className={'relative rounded-md shadow-sm'}>
      <textarea
        rows="2"
        placeholder={props.placeholder}
        className="block w-full pl-3 py-2 border border-gray-300 rounded-md text-base focus:outline-none focus:border-blue-500"
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}
