import { Result } from 'antd';

function SubscriptionsSuccess() {
  return (
    <>
      <Result
        status="success"
        title="Erfolgreich abonniert!"
        subTitle="Danke für dein Vertrauen, dein Account wird nun aktiviert!"
      />
    </>
  );
}

export default SubscriptionsSuccess;
