import React from 'react';
import Imprint from './Imprint';
import Privacy from './Privacy';
import TermsOfService from './TermsOfService';

export default function LegalContainer(props) {
  return (
    <>
      <div className={'p-4 text-base overflow-auto no-scrollbar'}>
        {props.text === 'imprint' && <Imprint />}
        {props.text === 'tos' && <TermsOfService />}
        {props.text === 'privacy' && <Privacy />}
      </div>
    </>
  );
}
