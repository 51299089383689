import React, { useEffect } from 'react';
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  notification,
  Typography,
} from 'antd';
import RestaurantSettings from './RestaurantSettings';
import axiosApiInstance from '../../Service/API';
import { useIdentity } from '../Utils/useIdentity';

const { Title } = Typography;

const openNotificationWithIcon = (type, msg) => {
  notification[type]({
    message: msg,
  });
};

function UserSettings() {
  const [form] = Form.useForm();
  const { user, tenant } = useIdentity();

  useEffect(() => {
    form.setFieldsValue({
      name: tenant?.name,
      email: tenant?.email,
      newPassword: '',
      newPasswordCheck: '',
    });
  }, [tenant]);

  const onFinish = (values) => {
    if (values.newPassword !== values.newPasswordCheck) {
      form.setFields([
        {
          name: 'newPassword',
          errors: ['Passwörter stimmen nicht überein'],
        },
        {
          name: 'newPasswordCheck',
          errors: ['Passwörter stimmen nicht überein'],
        },
      ]);
    } else {
      values['password'] = values.newPassword;
      delete values.newPassword;
      delete values.newPasswordCheck;
      delete values.email;
      axiosApiInstance.patch(`users/${user.uuid}`, values).then((response) => {
        if (response.status === 200) {
          openNotificationWithIcon('success', 'Daten Erfolgreich geändert!');
        } else {
          openNotificationWithIcon('error', 'Fehler beim ändern der Daten!');
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    openNotificationWithIcon('error', 'Fehler beim Ändern der Daten!');
  };

  return (
    <Card title="Einstellungen">
      <Row gutter={24}>
        <Col span={12}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Title level={3}>Deine Daten</Title>
          </div>
          <Form
            form={form}
            name="userSettings"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              name: '',
              email: '',
              newPassword: '',
              newPasswordCheck: '',
            }}
          >
            <label>Name</label>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: 'Bitte einen Namen eingeben' },
              ]}
            >
              <Input />
            </Form.Item>
            <label>E-Mail</label>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Bitte E-Mail eingeben' }]}
            >
              <Input disabled />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Title level={4}>Passwort ändern</Title>
            </div>
            <Row gutter={12}>
              <Col span={12}>
                <label>Neues Passwort</label>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: false,
                      message: 'Bitte ihr neues Passwort eingeben!',
                    },
                  ]}
                >
                  <Input.Password autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <label>Neues Passwort wiederholen</label>
                <Form.Item
                  name="newPasswordCheck"
                  rules={[
                    {
                      required: false,
                      message: 'Bitte ihr neues Passwort erneut eingeben!',
                    },
                  ]}
                >
                  <Input.Password autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Daten speichern
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <RestaurantSettings />
        </Col>
      </Row>
    </Card>
  );
}

export default UserSettings;
