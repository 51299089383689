import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export default function PaymentStatus(props) {
  const { push } = useHistory();

  return (
    <>
      {props.status === 'succeeded' && (
        <svg
          viewBox="0 0 24 24"
          className="icon-text-schmeckn-blue w-16 h-16 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          />
        </svg>
      )}
      {props.status === 'failed' && (
        <svg
          viewBox="0 0 24 24"
          className="text-red-500 w-16 h-16 mx-auto my-6"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="currentColor"
              d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"
            />
          </svg>
        </svg>
      )}
      <div className="text-center">
        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
          {props.status === 'succeeded' && 'Zahlung erfolgreich!'}
          {props.status === 'failed' && 'Zahlung fehlgeschlagen!'}
        </h3>
        <p className="text-gray-600 px-8">
          {props.status === 'succeeded' && 'Vielen Dank für deine Bestellung.'}
          {props.status === 'failed' &&
            'Leider ist während dem Bezahlvorgang etwas schief gelaufen.'}
        </p>
        <p>
          {props.status === 'succeeded' &&
            'Du erhälst gleich eine E-Mail mit deiner Bestellbestätigung.'}
          {props.status === 'failed' &&
            'Sollte dieser Fehler öfter auftreten, kontaktiere uns bitte unter:'}
        </p>
        <p className={'mb-0'}>
          {props.status === 'succeeded' &&
            'Deine Bestellung wird vorraussichtlich fertig sein um:'}
        </p>
        <div className={'flex justify-center font-bold text-xl my-2'}>
          {props.status === 'succeeded' &&
            `${moment(moment.now())
              .add(localStorage.getItem('estimatedTimeInProgress'), 'minute')
              .format('HH:mm')}Uhr`}
        </div>
        {props.status === 'succeeded' && (
          <p>
            Lass es dir <b>schmeckn</b>!{' '}
          </p>
        )}
        {props.status === 'failed' && (
          <p>
            <b>support@schmeckn.de</b>
          </p>
        )}
        <div className="py-6 text-center">
          <button
            className="px-12 bg-schmeckn-normal text-white hover:text-white font-semibold py-3 rounded-md"
            onClick={() => {
              push(window.location.pathname);
              props.setPaymentStatus('');
            }}
          >
            Zurück zur Speisekarte
          </button>
        </div>
      </div>
    </>
  );
}
