import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Switch as AntSwitch,
  Tag,
  Form,
  message,
  Input,
  Popconfirm,
} from 'antd';
import axiosApiInstance from '../../Service/API';
import { Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import CategoriesItems from './CategoriesItems';
import {
  EditOutlined,
  EditTwoTone,
  InfoCircleOutlined,
  PlusOutlined,
  DeleteTwoTone,
} from '@ant-design/icons';
import BackendModal from '../Utils/BackendModal';
import { useIdentity } from '../Utils/useIdentity';

function MenuCards() {
  const [loading, setLoading] = useState(true);
  const [menuCards, setMenuCards] = useState([]);
  const [selectedCategoryKeys, setSelectedCategoryKeys] = useState([]);
  const [currentCard, setCurrentCard] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { path, url } = useRouteMatch();
  const [form] = Form.useForm();
  const { tenant } = useIdentity();

  const toggleModal = () => {
    form.resetFields();
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    getMenuCards();
  }, [tenant]);

  const getMenuCards = () => {
    setLoading(true);
    axiosApiInstance.get('/menu-cards/self').then((response) => {
      setMenuCards(response.data);
      setLoading(false);
    });
  };

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Card
            title={`Speisekarten`}
            style={{ height: '100%', overflow: 'auto' }}
            loading={loading}
          >
            <Button
              size="small"
              style={{ marginBottom: '15px' }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                toggleModal();
                setEditMode(false);
              }}
            >
              Neue Speisekarte erstellen
            </Button>
            <Row gutter={[16, 16]}>
              {menuCards.map((menuCard) => (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  xxl={6}
                  key={menuCard.uuid}
                >
                  <Card
                    title={
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {menuCard.name}{' '}
                          <Tag
                            style={{ marginLeft: '10px' }}
                            color={menuCard.isActive ? 'green' : 'red'}
                          >
                            {menuCard.isActive ? 'Aktiv' : 'Inaktiv'}
                          </Tag>
                        </div>
                        <div
                          style={{
                            fontSize: '12px',
                            color: '#6b6b6b',
                            fontWeight: '400',
                          }}
                        >
                          {menuCard.description}
                        </div>
                      </>
                    }
                    extra={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EditTwoTone
                          style={{ fontSize: '24px', marginRight: '15px' }}
                          onClick={() => {
                            setEditMode(true);
                            setCurrentCard(menuCard);
                            // Cant use toggleModal() here since its buggy with the modalVisible state somehow
                            form.resetFields();
                            setModalVisible(true);
                          }}
                        />
                        <Popconfirm
                          title={`Speisekarte wirklich löschen?`}
                          onConfirm={() => {
                            axiosApiInstance
                              .delete(`/menu-cards/${menuCard.uuid}`)
                              .then((response) => {
                                if (response.status === 200) {
                                  getMenuCards();
                                  message.success(
                                    'Speisekarte erfolgreich gelöscht!',
                                  );
                                } else {
                                  message.error(
                                    'Speisekarte löschen fehlgeschlagen!',
                                  );
                                }
                              });
                          }}
                          okText="Ja"
                          cancelText="Nein"
                        >
                          <DeleteTwoTone
                            style={{ fontSize: '24px' }}
                            twoToneColor="#ff4d4f"
                          />
                        </Popconfirm>
                      </div>
                    }
                  >
                    <div style={{ marginTop: '10px' }}>
                      <Button
                        ghost
                        size={'small'}
                        type={'primary'}
                        icon={<EditOutlined />}
                      >
                        <Link to={`${url}/${menuCard.uuid}`}>
                          {' '}
                          Kategorien und Gerichte bearbeiten
                        </Link>
                      </Button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Route>
        <Route path={`${path}/:menuCardUuid`}>
          <CategoriesItems
            loading={loading}
            setLoading={setLoading}
            setSelectedCategoryKeys={setSelectedCategoryKeys}
            selectedCategoryKeys={selectedCategoryKeys}
            currentCard={currentCard}
            menuCards={menuCards}
            getMenuCards={getMenuCards}
          />
        </Route>
      </Switch>

      <BackendModal
        name={'Speisekarte'}
        editMode={editMode}
        toggleModal={toggleModal}
        modalVisible={modalVisible}
        onOk={() => {
          if (!editMode) {
            form.validateFields().then((values) => {
              axiosApiInstance
                .get('/restaurants/self', values)
                .then((response) => {
                  values['restaurants'] = [{ uuid: response.data[0].uuid }];
                  axiosApiInstance
                    .post('/menu-cards', values)
                    .then((response) => {
                      if (response.status === 201) {
                        getMenuCards();
                        toggleModal();
                        message.success('Speisekarte erfolgreich angelegt');
                        form.resetFields();
                      } else {
                        message.error('Speisekarte anlegen fehlgeschlagen');
                      }
                    });
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            });
          } else {
            form
              .validateFields()
              .then((values) => {
                axiosApiInstance
                  .patch(`/menu-cards/${currentCard.uuid}`, values)
                  .then((response) => {
                    if (response.status === 200) {
                      message.success('Speisekarte erfolgreich geändert');
                      getMenuCards();
                      toggleModal();
                      form.resetFields();
                    } else {
                      message.error('Speisekarte ändern fehlgeschlagen');
                    }
                  });
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }
        }}
        content={
          <Form
            form={form}
            layout="vertical"
            fields={[
              { name: 'name', value: editMode ? currentCard.name : '' },
              {
                name: 'description',
                value: editMode ? currentCard.description : '',
              },
              { name: 'isActive', value: editMode ? currentCard.isActive : '' },
            ]}
          >
            <Form.Item
              label="Name"
              name="name"
              hasFeedback
              rules={[{ required: true, message: 'Bitte den Namen eingeben' }]}
              tooltip={{
                title: (
                  <div>
                    Name der Speisekarte
                    <br /> z.B: Sommerkarte, Weihnachtskarte
                  </div>
                ),
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input placeholder="Weihnachtskarte" />
            </Form.Item>
            <Form.Item
              label="Beschreibung"
              name="description"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Bitte eine Beshchreibung eingeben',
                },
              ]}
              tooltip={{
                title: (
                  <div>
                    Beschreibung der Speisekarte
                    <br /> z.B: Für Weihnachten
                  </div>
                ),
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input placeholder="Für Weihnachten" />
            </Form.Item>
            {editMode && (
              <Form.Item name="isActive" valuePropName="checked">
                <AntSwitch
                  checkedChildren="Aktiv"
                  unCheckedChildren="Inaktiv"
                />
              </Form.Item>
            )}
          </Form>
        }
      />
    </>
  );
}

export default MenuCards;
