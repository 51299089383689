import React from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import PaymentStripeKit from './PaymentStripeKit';
import Env from '../../../Service/Env';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(Env.STRIPE_PK);
  }
  return stripePromise;
};

export default function PaymentStripe(props) {
  const clientSecret = props.stripeClientSecret;

  const appearance = {
    theme: 'minimal',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Elements options={options} stripe={getStripe()}>
      <PaymentStripeKit returnUrl={props.returnUrl} />
    </Elements>
  );
}
