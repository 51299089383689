import React, { useState } from 'react';
import { Button, message } from 'antd';
import axiosApiInstance from '../../Service/API';

function StripeRedirectButton({ buttonTitle, endpoint }) {
  const [isLoading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    axiosApiInstance
      .get(endpoint)
      .then((res) => {
        window.location = res.data.url;
        message.info('Weiterleitung auf stripe.com ...', 1000);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button type="primary" loading={isLoading} onClick={onClick}>
        {buttonTitle}
      </Button>
    </>
  );
}

export function OpenCustomerButton() {
  return StripeRedirectButton({
    buttonTitle: 'Stripe öffnen',
    endpoint: '/stripe/seller/portal',
  });
}

export function OpenAccountButton() {
  return StripeRedirectButton({
    buttonTitle: (
      <>
        <b>stripe.com</b> öffnen
      </>
    ),
    endpoint: '/stripe/seller/account',
  });
}
