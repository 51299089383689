import React from 'react';

export default function TermsOfService() {
  return (
    <>
      Allgemeine Geschäftsbedingungen der Firma schmeckn Ergel & Schäfer GbR.
      <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §1 Geltung gegenüber Unternehmern und Begriffsdefinitionen
      </p>
      (1) Die nachfolgenden Allgemeinen Geschäftbedingungen gelten für alle
      Lieferungen zwischen uns und einem Verbraucher in ihrer zum Zeitpunkt der
      Bestellung gültigen Fassung.
      <br />
      <br />
      Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken
      abschließt, die überwiegend weder ihrer gewerblichen noch ihrer
      selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 BGB).
      <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §2 Zustandekommen eines Vertrages, Speicherung des Vertragstextes
      </p>
      (1) Die folgenden Regelungen über den Vertragsabschluss gelten für
      Bestellungen über unseren Internetshop https://schmeckn.de .<br />
      <br />
      <b>
        (2) Im Falle des Vertragsschlusses kommt der Vertrag mit
        <br />
        <div style={{ fontWeight: 'bold', marginLeft: '20px' }}>
          <br />
          schmeckn Ergel & Schäfer GbR.
          <br />
          Marvin Ergel & Max Robin Schäfer
          <br />
          Friedrich-Ebert-Straße 5<br />
          D-35428 Langgöns
          <br />
          Registernummer <br />
          Registergericht <br />
          <br />
        </div>
        zustande.
        <br />
      </b>
      <br />
      (3) Die Präsentation der Waren in unserem Internetshop stellen kein
      rechtlich bindendes Vertragsangebot unsererseits dar, sondern sind nur
      eine unverbindliche Aufforderungen an den Verbraucher, Waren zu bestellen.
      Mit der Bestellung der gewünschten Ware gibt der Verbraucher ein für ihn
      verbindliches Angebot auf Abschluss eines Kaufvertrages ab.
      <br />
      (4) Bei Eingang einer Bestellung in unserem Internetshop gelten folgende
      Regelungen: Der Verbraucher gibt ein bindendes Vertragsangebot ab, indem
      er die in unserem Internetshop vorgesehene Bestellprozedur erfolgreich
      durchläuft.
      <br />
      <br />
      Die Bestellung erfolgt in folgenden Schritten:
      <br />
      <br />
      <div className="ml-5">
        1) Auswahl der gewünschten Ware
        <br />
        2) Bestätigen durch Anklicken des Buttons „Warenkorb“ <br />
        3) Prüfung der Angaben im Warenkorb
        <br />
        4) Eingabe ihrer Daten
        <br />
        5) Verbindliche Absendung der Bestellung durch Anklicken des Buttons
        „Bestellen“ bzw. „Weiter zu PayPal“
        <br />
        <br />
      </div>
      Der Verbraucher kann vor dem verbindlichen Absenden der Bestellung durch
      Betätigen der in dem von ihm verwendeten Internet-Browser enthaltenen
      „Zurück“-Taste nach Kontrolle seiner Angaben wieder zu der Internetseite
      gelangen, auf der die Angaben des Kunden erfasst werden und Eingabefehler
      berichtigen bzw. durch Schließen des Internetbrowsers den Bestellvorgang
      abbrechen. Wir bestätigen den Eingang der Bestellung unmittelbar durch
      eine automatisch generierte E-Mail („Auftragsbestätigung“). Mit dieser
      nehmen wir Ihr Angebot an.
      <br />
      <br />
      (5) Speicherung des Vertragstextes bei Bestellungen über unseren
      Internetshop : Wir senden Ihnen die Bestelldaten und unsere AGB per E-Mail
      zu. Die AGB können Sie jederzeit auch unter https://schmeckn.de/agb
      einsehen. Ihre Bestelldaten sind aus Sicherheitsgründen nicht mehr über
      das Internet zugänglich.
      <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §3 Preise, Versandkosten, Zahlung, Fälligkeit
      </p>
      (1) Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer und
      sonstige Preisbestandteile. Hinzu kommen etwaige Versandkosten.
      <br />
      <br />
      (2) Der Verbraucher hat die Möglichkeit der Zahlung per PayPal,
      Kreditkarte( Visa, Mastercard, American Express ) .<br />
      <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>§4 Lieferung</p>
      (1) Sofern wir dies in der Produktbeschreibung nicht deutlich anders
      angegeben haben, sind alle von uns angebotenen Artikel sofort
      versandfertig. Die Lieferung erfolgt hier spätesten innerhalb von 1
      Werktagen. Dabei beginnt die Frist für die Lieferung im Falle der Zahlung
      per Vorkasse am Tag nach Zahlungsauftrag an die mit der Überweisung
      beauftragte Bank und bei allen anderen Zahlungsarten am Tag nach
      Vertragsschluss zu laufen. Fällt das Fristende auf einen Samstag, Sonntag
      oder gesetzlichen Feiertag am Lieferort, so endet die Frist am nächsten
      Werktag.
      <br />
      <br />
      (2) Die Gefahr des zufälligen Untergangs und der zufälligen
      Verschlechterung der verkauften Sache geht auch beim Versendungskauf erst
      mit der Übergabe der Sache an den Käufer auf diesen über. <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §5 Eigentumsvorbehalt
      </p>
      Wir behalten uns das Eigentum an der Ware bis zur vollständigen Bezahlung
      des Kaufpreises vor. <br />
      <br />
      ****************************************************************************************************
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §6 Widerrufsrecht des Kunden als Verbraucher
      </p>
      <b>
        Widerrufsrecht für Verbraucher
        <br />
      </b>
      <br />
      Verbrauchern steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei
      Verbraucher jede natürliche Person ist, die ein Rechtsgeschäft zu Zwecken
      abschließt, die überwiegend weder ihrer gewerblichen noch ihrer
      selbständigen beruflichen Tätigkeit zugerechnet werden können:
      <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        Widerrufsbelehrung
      </p>
      <br />
      <b>Widerrufsrecht</b>
      <br />
      <br />
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
      Vertrag zu widerrufen.
      <br />
      Die Widerrufsfrist beträgt vierzehn Tage, ab dem Tag des
      Vertragsabschlusses.
      <br />
      <br />
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
      <br />
      <div
        style={{ marginLeft: '20px', marginTop: '5px', marginBottom: '5px' }}
      >
        schmeckn Ergel & Schäfer GbR.
        <br />
        Marvin Ergel & Max Robin Schäfer
        <br />
        Friedrich-Ebert-Straße 5<br />
        D-35428 Langgöns
        <br />
        E-Mail info@schmeckn.de
        <br />
      </div>
      mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
      Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
      widerrufen, informieren. Sie können dafür das beigefügte
      Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      <br />
      <br />
      Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
      die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
      <br />
      <br />
      <b>Widerrufsfolgen</b>
      <br />
      <br />
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
      wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
      Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
      andere Art der Lieferung als die von uns angebotene, günstigste
      Standardlieferung gewählt haben), unverzüglich und spätestens binnen
      vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
      Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
      verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
      Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
      etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
      Rückzahlung Entgelte berechnet.
      <br />
      <br />
      Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist
      beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der
      dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
      Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits
      erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag
      vorgesehenen Dienstleistungen entspricht.
      <br />
      <br />
      <b>Ende der Widerrufsbelehrung</b>
      <br />
      <br />
      ****************************************************************************************************
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §7 Widerrufsformular
      </p>
      <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Muster-Widerrufsformular
      </p>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
      Formular aus und senden Sie es zurück.)
      <br />
      <div style={{ border: '1px solid #000000', padding: '10px' }}>
        An :<br />
        schmeckn Ergel & Schäfer GbR.
        <br />
        Marvin Ergel & Max Robin Schäfer
        <br />
        Friedrich-Ebert-Straße 5<br />
        D-35428 Langgöns
        <br />
        E-Mail info@schmeckn.de
        <br />
        <br />
        Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
        Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der
        folgenden Dienstleistung (*)
        <br />
        <br />
        _____________________________________________________
        <br />
        <br />
        Bestellt am (*)/erhalten am (*)
        <br />
        <br />
        __________________
        <br />
        <br />
        Name des/der Verbraucher(s)
        <br />
        <br />
        _____________________________________________________
        <br />
        <br />
        Anschrift des/der Verbraucher(s)
        <br />
        <br />
        <br />
        _____________________________________________________
        <br />
        <br />
        Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        <br />
        <br />
        __________________
        <br />
        <br />
        Datum
        <br />
        <br />
        __________________
        <br />
        <br />
      </div>
      (*) Unzutreffendes streichen.
      <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §8 Gewährleistung
      </p>
      Es gelten die gesetzlichen Gewährleistungsregelungen.
      <br />
      <br />
      <p style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
        §9 Vertragssprache
      </p>
      Als Vertragssprache steht ausschließlich Deutsch zur Verfügung.
      <br />
      <br />
      <br />
      <p>Stand der AGB Mär.2022</p>
      <p>
        <i>
          <a href="https://www.agb.de">Gratis AGB</a> erstellt von agb.de
        </i>
      </p>
    </>
  );
}
