import React, { useState, useEffect } from 'react';
import ItemModal from './ItemModal';
import { ConvertPriceAmount } from '../../../Service/Helper';

function ItemContainer(props) {
  let [isOpen, setIsOpen] = useState(false);
  let [finalPrice, setFinalPrice] = useState(props.item.price);

  // Check if the item has radio option and if yes, which one is default to set the base price correctly
  useEffect(() => {
    let newFinalPrice = finalPrice;
    if (props.item.extras.length > 0) {
      props.item.extras.forEach((extra) => {
        if (extra.menuExtraType === 'radio') {
          extra.options.forEach((option) => {
            if (option.isDefault) {
              newFinalPrice += option.priceDiff;
            }
          });
        }
      });
    }
    setFinalPrice(newFinalPrice);
  }, []);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    props.setModalState();
  };

  const tags = () => {
    if (props.item.tags.length !== 0) {
      let items = [];
      props.item.tags.forEach((tag) => {
        items.push(
          <div
            className="font-bold text-xs uppercase px-3 py-0.5 mr-1 bg-blue-200 icon-text-schmeckn-blue rounded-full"
            key={tag.uuid}
          >
            #{tag.name}
          </div>,
        );
      });
      return items;
    }
  };

  return (
    <>
      <div
        className="bg-white rounded-xl py-2 px-4 mb-2 border shadow cursor-pointer"
        onClick={toggleModal}
      >
        <div className="flex justify-between mb-1 text-gray-600">
          <div className={'font-bold text-base font-semibold'}>
            {props.item.name}
          </div>
          <div className="font-bold text-base">
            {ConvertPriceAmount(finalPrice)}€
          </div>
        </div>
        <div className="flex justify-between text-gray-600 text-xs">
          <div className="w-4/5 top-0 whitespace-nowrap overflow-hidden overflow-ellipsis">
            {props.item.description}
          </div>
        </div>
        <div className="flex flew-row mt-2">{tags()}</div>
      </div>

      <ItemModal
        isOpen={isOpen}
        item={props.item}
        toggleModal={() => toggleModal()}
        addToBasket={props.addToBasket}
        operation={'add'}
        canAcceptOrders={props.canAcceptOrders}
      />
    </>
  );
}

export default ItemContainer;
