import React, { useEffect, useState } from 'react';
import axiosApiInstance from '../Service/API';

function Chatwoot({
  baseUrl = 'https://app.chatwoot.com',
  websiteToken,
  settings = { locale: 'de' },
  userIdentifier = null,
  user = {},
  onReady = (chatwoot) => {},
  handleIdentityValidation = false,
}) {
  const [isReady, setReady] = useState(false);
  const [scriptId, setScriptId] = useState(null);

  const fetchIdentifierHashForCurrentUser = async () => {
    const res = await axiosApiInstance.get('/chatwoot/identifier-hash');
    return res?.data?.identifierHash;
  };

  const getScript = () => {
    return document.getElementById(`${scriptId}`);
  };

  const hasScript = () => {
    if (!scriptId) return false;
    return !!getScript();
  };

  const addScript = () => {
    if (hasScript()) return;
    window.chatwootSettings = settings;
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.src = `${baseUrl}/packs/js/sdk.js`;
    script.defer = true;
    script.async = true;

    script.id = btoa(`${Date.now()}.${script.src}`);
    setScriptId(script.id);

    window.addEventListener('chatwoot:ready', () => setReady(true));

    script.onload = () => {
      window.chatwootSDK.run({
        baseUrl: baseUrl,
        websiteToken: websiteToken,
      });
    };

    head.appendChild(script);
  };

  useEffect(() => {
    if (!websiteToken) throw new Error('No chatwoot websiteToken provided');
    addScript();
  }, []);

  useEffect(() => {
    if (!isReady) return;
    const chatwoot = window.$chatwoot;
    onReady(chatwoot);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res) => {
      if (handleIdentityValidation) {
        const identifierHash = await fetchIdentifierHashForCurrentUser();
        user = { ...user, identifier_hash: identifierHash };
      }
      res();
    }).then(() => {
      if (userIdentifier && user) chatwoot.setUser(userIdentifier, user);
    });
  }, [isReady]);

  return <></>;
}

export default Chatwoot;
