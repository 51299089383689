import React, { useState } from 'react';
import { Menu, Button, Popconfirm, message, Form, Input, Row, Col } from 'antd';
import axiosApiInstance from '../../Service/API';
import BackendModal from '../Utils/BackendModal';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

function Categories(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();

  const toggleModal = () => {
    form.resetFields();
    setModalVisible(!modalVisible);
  };

  return (
    <>
      <Row>
        <Button
          size="small"
          style={{ width: '100%', marginBottom: '10px' }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            toggleModal();
            setEditMode(false);
          }}
        >
          Hinzufügen
        </Button>
      </Row>
      <Row gutter={12} style={{ marginBottom: '10px' }}>
        <Col span={12}>
          <Button
            block
            disabled={props.selectedCategory === ''}
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              toggleModal();
              setEditMode(true);
            }}
          >
            Bearbeiten
          </Button>
        </Col>
        <Col span={12}>
          <Popconfirm
            title={`Kategorie wirklich löschen?`}
            onConfirm={() => {
              props.setLoading(true);
              axiosApiInstance
                .delete(`/menu-categories/${props.selectedCategory.uuid}`)
                .then((response) => {
                  if (response.status === 200) {
                    message.success('Kateogrie erfolgreich gelöscht!');
                    props.setSelectedCategory('');
                    props.getMenuCards();
                  } else {
                    message.error('Kateogrie löschen fehlgeschlagen!');
                  }
                });
            }}
            okText="Ja"
            cancelText="Nein"
            disabled={props.selectedCategory === ''}
          >
            <Button
              block
              disabled={props.selectedCategory === ''}
              size="small"
              type="danger"
              icon={<DeleteOutlined />}
            >
              Löschen
            </Button>
          </Popconfirm>
        </Col>
      </Row>
      <Menu selectedKeys={props.selectedCategoryKeys}>
        {props.currentCard.categories.map((category) => (
          <Menu.Item
            key={category.uuid}
            onClick={() => {
              props.setSelectedCategory(category);
              props.setSelectedCategoryKeys([category.uuid]);
            }}
          >
            {category.name}
          </Menu.Item>
        ))}
      </Menu>
      <BackendModal
        name={'Kategorie'}
        editMode={editMode}
        toggleModal={toggleModal}
        modalVisible={modalVisible}
        onOk={() => {
          if (!editMode) {
            form
              .validateFields()
              .then((values) => {
                props.setLoading(true);
                axiosApiInstance
                  .post('menu-categories', values)
                  .then((response) => {
                    if (response.status === 201) {
                      let categories = [];
                      categories.push({ uuid: response.data.uuid });
                      for (let item of props.currentCard.categories) {
                        categories.push({ uuid: item.uuid });
                      }
                      axiosApiInstance
                        .patch(`/menu-cards/${props.currentCard.uuid}`, {
                          categories: categories,
                        })
                        .then((response) => {
                          if (response.status === 200) {
                            props.getMenuCards();
                            message.success('Kateogrie erfolgreich verknüpft!');
                          } else {
                            message.error(
                              'Kateogrie verknüpfen fehlgeschlagen!',
                            );
                          }
                        });
                      message.success('Kategorie erfolgreich angelegt');
                      toggleModal();
                      form.resetFields();
                    } else {
                      message.error('Kategorie anlegen fehlgeschlagen');
                    }
                  });
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          } else {
            form
              .validateFields()
              .then((values) => {
                props.setLoading(true);
                axiosApiInstance
                  .patch(
                    `/menu-categories/${props.selectedCategory.uuid}`,
                    values,
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      message.success('Kategorie erfolgreich geändert');
                      props.getMenuCards();
                      props.setSelectedCategory(response.data);
                      toggleModal();
                      form.resetFields();
                    } else {
                      message.error('Kategorie ändern fehlgeschlagen');
                    }
                  });
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }
        }}
        content={
          <Form
            form={form}
            layout="vertical"
            fields={[
              {
                name: 'name',
                value: editMode ? props.selectedCategory.name : '',
              },
            ]}
          >
            <Form.Item
              label="Name"
              name="name"
              hasFeedback
              rules={[{ required: true, message: 'Bitte den Namen eingeben' }]}
              tooltip={{
                title: (
                  <div>
                    Name der Kategorie
                    <br /> z.B: Pizza, Nudeln, Salat
                  </div>
                ),
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input placeholder="Pizza" />
            </Form.Item>
          </Form>
        }
      />
    </>
  );
}

export default Categories;
