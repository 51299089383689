import React from 'react';

export default function Restaurant404() {
  return (
    <>
      <div className="min-w-screen min-h-screen bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative">
        <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
          <div className="w-full md:w-1/2">
            <div className="mb-10 lg:mb-20"></div>
            <div className="mb-10 md:mb-20 text-gray-600 font-light">
              <h1 className="font-black text-3xl lg:text-5xl text-schmeckn-normal mb-10">
                Oh Oh!
              </h1>
              <div className={'text-2xl'}>Wir konnten das gesuchte </div>
              <div className={'text-2xl'}>Restaurant leider nicht finden.</div>
            </div>
          </div>
          <div className="w-full md:w-1/2 text-center">
            <img
              src="/images/restaurant_404.svg"
              alt={'error404'}
              style={{ width: '30em', height: 'auto' }}
            />
          </div>
        </div>
        <div className="w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform" />
        <div className="w-96 h-full bg-indigo-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform" />
      </div>
    </>
  );
}
