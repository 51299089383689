import React, { useEffect, useState } from 'react';
import { ConvertPriceAmount } from '../../../Service/Helper';

export default function ItemExtraCheckbox(props) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (props.operation === 'edit') {
      props.itemExtras.forEach((extra) => {
        if (extra.uuid === props.itemExtra.uuid) {
          setChecked(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    let newAllExtras = [...props.itemExtras];
    if (checked) {
      newAllExtras.push({
        uuid: props.itemExtra.uuid,
        optionsIndex: 0,
        menuExtraType: 'checkbox',
        name: props.itemExtra.name,
        priceDiff: props.itemExtra.options[0].priceDiff,
      });
    } else {
      newAllExtras = newAllExtras.filter(
        (item) => item.uuid !== props.itemExtra.uuid,
      );
    }
    props.setItemExtras(newAllExtras);
  }, [checked]);

  return (
    <div className="flex items-center justify-start mb-3 w-full">
      <input
        type="checkbox"
        defaultChecked={false}
        className="hidden"
        style={{ width: '100%' }}
      />
      <label
        className={'relative w-10 h-5 flex select-none cursor-pointer'}
        onClick={() => {
          setChecked(!checked);
        }}
      >
        <span
          className={`absolute left-0 top-0 h-full w-full bg-gray-200 rounded-full ${
            checked ? 'bg-schmeckn-normal' : 'bg-gray-100'
          }`}
        />
        <span
          className={`h-5 w-5 border-2 absolute z-8 rounded-full bg-white transition-transform duration-300 ease-in-out flex justify-center items-center ${
            checked
              ? 'transform translate-x-full border-blue-500'
              : 'border-gray-100'
          }`}
        ></span>
      </label>
      <div className={`text-sm ml-2 ${checked && 'font-semibold'}`}>{`${
        props.itemExtra.name
      } (+${ConvertPriceAmount(props.itemExtra.options[0].priceDiff)}€)`}</div>
    </div>
  );
}
