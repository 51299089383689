import React, { useEffect, useState } from 'react';
import { InputNumber, Space } from 'antd';

// TODO: Cannot work as a standalone component
export default function TimeInProgress({
  timeInProgressObj = {
    spanSize: 10,
    spanTime: 10,
    baseTime: 10,
  },
  setTimeInProgressObj = () => {},
}) {
  const { spanSize, spanTime, baseTime } = timeInProgressObj;
  const [amountOfOrdersInProgress, setAmountOfOrdersInProgress] = useState(10);
  const [estimatedTimeInProgress, setEstimatedTimeInProgress] = useState(0);

  const updateTimeInProgressObj = (key, value) => {
    let tmpTimeInProgress = { ...timeInProgressObj };
    tmpTimeInProgress[key] = value;
    setTimeInProgressObj(tmpTimeInProgress);
  };

  const calculateEstimatedTimeInProgress = ({
    spanSize,
    spanTime,
    baseTime,
    amountOfOrdersInProgress,
  }) => {
    return baseTime + (spanTime * amountOfOrdersInProgress) / spanSize;
  };

  useEffect(() => {
    const etip = calculateEstimatedTimeInProgress({
      spanSize: spanSize,
      spanTime: spanTime,
      baseTime: baseTime,
      amountOfOrdersInProgress: amountOfOrdersInProgress,
    });
    setEstimatedTimeInProgress(Math.round(etip * 100) / 100);
    updateTimeInProgressObj();
  }, [spanSize, spanTime, baseTime, amountOfOrdersInProgress]);

  return (
    <>
      <Space direction="vertical">
        <table>
          <tbody>
            <tr>
              <td>~ Zeit pro Bestellung</td>
              <td>
                <InputNumber
                  addonAfter="Minute(n)"
                  value={baseTime}
                  style={{
                    marginLeft: '.5em',
                    width: '100%',
                    marginBottom: '.75em',
                  }}
                  min={1}
                  onChange={(e) => updateTimeInProgressObj('baseTime', e)}
                />
              </td>
            </tr>
            <tr>
              <td>~ Gleichzeitige Bestellungen</td>
              <td>
                <InputNumber
                  addonAfter="Bestellung(en)"
                  value={spanSize}
                  style={{
                    marginLeft: '.5em',
                    width: '100%',
                    marginBottom: '.75em',
                  }}
                  min={1}
                  onChange={(e) => updateTimeInProgressObj('spanSize', e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                ~ Zeit pro Bestellungen wenn
                <br />
                "gleichzeitige Bestellungen" ausgereitzt sind
              </td>
              <td>
                <InputNumber
                  addonAfter="Minute(n)"
                  value={spanTime}
                  style={{
                    marginLeft: '.5em',
                    width: '100%',
                    marginBottom: '.75',
                  }}
                  min={1}
                  onChange={(e) => updateTimeInProgressObj('spanTime', e)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: '1em', marginBottom: '1em' }}>
          <b>Beispiel:</b>
          <br />
          Wenn{' '}
          <InputNumber
            addonAfter="Bestellung(en)"
            value={amountOfOrdersInProgress}
            style={{ width: '40%' }}
            size="small"
            min={1}
            onChange={setAmountOfOrdersInProgress}
          />{' '}
          den Status "In Bearbeitung"{' '}
          {amountOfOrdersInProgress === 1 ? 'hat' : 'haben'},<br />
          benötigt die nächste Bestellung{' '}
          <b>
            ~{estimatedTimeInProgress}{' '}
            {estimatedTimeInProgress === 1 ? 'Minute' : 'Minuten'}
          </b>
          .
        </div>
      </Space>
    </>
  );
}
