import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Env from '../../../Service/Env';

export default function PaymentPayPal(props) {
  return (
    <PayPalScriptProvider
      options={{ 'client-id': Env.PAYPAL_CLIENT_ID, currency: 'EUR' }}
    >
      <PayPalButtons
        style={{
          layout: 'horizontal',
          shape: 'pill',
          label: 'checkout',
          tagline: false,
        }}
        createOrder={() => {
          return props.paypalId;
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(() => {
            actions.redirect(`${props.returnUrl}?redirect_status=succeeded`);
          });
        }}
        onCancel={(data, actions) => {
          actions.redirect(`${props.returnUrl}?redirect_status=failed`);
        }}
      />
    </PayPalScriptProvider>
  );
}
