class EnvNotFoundError extends Error {
  constructor(key) {
    super(`Env "${key}" not found`);
  }
}

class Env {
  static defaults = {
    API_BASE_URL: 'https://staging.schmeckn.de/api/v1',
    BASIC_AUTH_HEADER: '',
    WITH_CREDENTIALS: false,
    STRIPE_PK:
      'pk_test_51KVhrPIQwKvDnZOJKciKnhmGpSTGg2N8wthYCnpeMkmOrzFZdKH5wX8pgF8EhU5hSSUx05NdWo8A5eKeWiTurQln00AuWQUH9N',
    PAYPAL_ENV: 'production',
    PAYPAL_CLIENT_ID:
      'AQ6cEZl14mbqnRRDnp37W0uxpx_nB0KmMs6aiE63n-Lan0f2UnSGD2hwEZqz89nUB8Mx4L-0CGxnMWwS',
    GOOGLE_API_KEY: 'AIzaSyABtaHXnyHzBOh1HB4JMWItAUqhkB9qnXg',
    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-223824182-1',
    SENTRY_ENABLE: false,
    SENTRY_DSN:
      'https://85cb5016f8e74416bdcfe4ee0851fdec@o1163468.ingest.sentry.io/6269815',
    SENTRY_ENVIRONMENT: 'production',
    CHATWOOT_WEBSITE_TOKEN: 'SpuMi1xZhqcvWWj2LRmNw8wh',
    FEATURE_DRIVER_MANAGEMENT: false,
  };

  static get(key) {
    const value =
      process.env[`REACT_APP_${key}`] || window?.env[key] || this.defaults[key];
    if (value === undefined) throw new EnvNotFoundError(key);
    return value;
  }

  static get API_BASE_URL() {
    return this.get('API_BASE_URL');
  }

  static get BASIC_AUTH_HEADER() {
    return this.get('BASIC_AUTH_HEADER');
  }

  static get WITH_CREDENTIALS() {
    return this.get('WITH_CREDENTIALS');
  }

  static get STRIPE_PK() {
    return this.get('STRIPE_PK');
  }

  static get PAYPAL_ENV() {
    return this.get('PAYPAL_ENV');
  }

  static get PAYPAL_CLIENT_ID() {
    return this.get('PAYPAL_CLIENT_ID');
  }

  static get GOOGLE_API_KEY() {
    return this.get('GOOGLE_API_KEY');
  }

  static get GOOGLE_ANALYTICS_TRACKING_CODE() {
    return this.get('GOOGLE_ANALYTICS_TRACKING_CODE');
  }

  static get SENTRY_ENABLE() {
    return this.get('SENTRY_ENABLE');
  }

  static get SENTRY_DSN() {
    return this.get('SENTRY_DSN');
  }

  static get SENTRY_ENVIRONMENT() {
    return this.get('SENTRY_ENVIRONMENT');
  }

  static get CHATWOOT_WEBSITE_TOKEN() {
    return this.get('CHATWOOT_WEBSITE_TOKEN');
  }

  static get FEATURE_DRIVER_MANAGEMENT() {
    return this.get('FEATURE_DRIVER_MANAGEMENT');
  }
}

export default Env;
