import {
  Card,
  Col,
  List,
  Row,
  Space,
  Table,
  Tag,
  Timeline,
  Typography,
} from 'antd';
import Translation from '../../Service/Translation';
import { ConvertPriceAmount } from '../../Service/Helper';

function OrderItemsTable({ items }) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Notiz',
      dataIndex: 'comment',
      render: (comment) => comment !== '' && <Tag color="gold">{comment}</Tag>,
    },
    {
      title: 'Preis',
      dataIndex: 'fullPrice',
      render: (fullPrice) => <span>{ConvertPriceAmount(fullPrice)}€</span>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowKey="uuid"
      pagination={false}
      bordered
      size="small"
      expandable={{
        expandedRowRender: (item) =>
          item.extras.length > 0 ? (
            <OrderExtrasList extras={item.extras} />
          ) : (
            <div style={{ margin: 0, padding: 0 }} />
          ),
        expandIcon: () => null,
        expandIconColumnIndex: -1,
        rowExpandable: (item) => item.extras.length > 0,
        defaultExpandAllRows: true,
        expandedRowClassName: () => 'expanded-order-details',
      }}
    />
  );
}

function OrderExtrasList({ extras }) {
  return (
    <List
      size="small"
      dataSource={extras}
      renderItem={(extra) => (
        <List.Item style={{ padding: '2px 24px' }}>
          <Typography.Text>
            <i>
              {extra.name + (extra.optionName ? ': ' + extra.optionName : '')}
            </i>
          </Typography.Text>
        </List.Item>
      )}
    />
  );
}

function OrderDetails({ order }) {
  const getTimelineItemForOrderState = (
    forOrderState,
    currentOrderState = order.orderStateName,
  ) => {
    const orderStateOrder = [
      'open',
      'in-progress',
      'ready',
      'in-delivery',
      'done',
    ];
    const currentOrderStateIndex = orderStateOrder.indexOf(currentOrderState);
    const forOrderStateIndex = orderStateOrder.indexOf(forOrderState);

    let color = 'gray';
    if (forOrderState === 'canceled') {
      color = 'red';
    } else if (currentOrderStateIndex === orderStateOrder.length - 1) {
      color = 'green';
    } else if (currentOrderStateIndex === forOrderStateIndex) {
      color = 'blue';
    } else if (currentOrderStateIndex > forOrderStateIndex) {
      color = 'green';
    }
    return (
      <Timeline.Item key={forOrderState} color={color}>
        {Translation.orderStateName(forOrderState)}
      </Timeline.Item>
    );
  };

  return (
    <Card title={`Bestellung #${order.orderNumber}`}>
      <Row gutter={[24, 24]}>
        <Col lg={3} xs={5}>
          <Timeline style={{ marginTop: '1rem' }}>
            {['open', 'in-progress', 'ready', 'in-delivery', 'done'].map(
              (orderState) => getTimelineItemForOrderState(orderState),
            )}
            {order.orderStateName === 'canceled' &&
              getTimelineItemForOrderState(order.orderStateName)}
          </Timeline>
        </Col>
        <Col lg={21} xs={19}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexFlow: 'column',
              }}
            >
              <div>
                Name: {order.customer.firstName} {order.customer.lastName}
              </div>
              <div>Telefon: {order.customer.phoneNumber}</div>
              <div>
                E-Mail:{' '}
                <a href={`mailto:${order.customer.email}`}>
                  {order.customer.email}
                </a>
              </div>
              <div>
                Bestellnotiz:{' '}
                {order.comment !== '' && (
                  <Tag color="gold">{order.comment}</Tag>
                )}
              </div>
            </div>
            <OrderItemsTable items={order.items} order={order} />
          </Space>
        </Col>
      </Row>
    </Card>
  );
}

export default OrderDetails;
